/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { nombre, asistencia, estados, desfase },
} = checkout;

const validations = [
  Yup.object().shape({
    [nombre.name]: Yup.string().required(nombre.errorMsg),
    [asistencia.name]: Yup.string().required(asistencia.errorMsg),
    [estados.name]: Yup.array()
      .of(Yup.string())
      .required(estados.errorMsg)
      .min(1, "Debe seleccionar al menos un estado"),
    [desfase.name]: Yup.number()
      .required(desfase.errorMsg)
      .min(0, "El desfase no puede ser menor a 0")
      .max(1, "El desfase no puede ser mayor a 1"),
  }),
];

export default validations;
