import { cicloApi } from "../view/ciclo.service";

export const editCiclo = cicloApi.injectEndpoints({
  endpoints: (builder) => ({
    editCiclo: builder.mutation({
      query: (data) => {
        return {
          url: `/v1/api/ciclo/custom_update/`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "Ciclo", id },
              { type: "Ciclo", id: "LIST" },
            ]
          : [{ type: "Ciclo", id: "LIST" }],
    }),
  }),
});

export const { useEditCicloMutation } = editCiclo;
