/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// formik components
import { Form, Formik } from "formik"; // @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card"; // Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton"; // NewExamenForm page components
import ExamenInfo from "./components/examen.info"; // NewExamenForm layout schemas for form and form feilds
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import MDTypography from "components/MDTypography";
import { EXAMEN_LIST_URL } from "config/bases_url";
import { useRedirectForm } from "hooks/useRedirectForm";
import {
  useCreateExamenMutation,
  useEditExamenMutation,
  useLazyGetExamenByIdQuery,
} from "services";
import FormErrorHandler from "../../../../handlers/FormErrorHandler/form-error.handler";
import { ErrorBundary } from "../../../../config/ErrorBundary/error-bundary";
import { getModifiedFields } from "helpers/functions";

function NewExamenForm() {
  const [
    createExamen,
    {
      isSuccess: isSuccessC,
      isLoading: isLoadingC,
      isError: isErrorC,
      error: errorC,
    },
  ] = useCreateExamenMutation();
  const [
    editExamen,
    {
      isSuccess: isSuccessE,
      isLoading: isLoadingE,
      isError: isErrorE,
      error: errorE,
    },
  ] = useEditExamenMutation();

  const { id } = useParams();
  const { formId, formField } = form;
  const currentValidation = validations[0];
  const navigate = useNavigate();

  const [getExamenById, { data: examen }] = useLazyGetExamenByIdQuery();

  useRedirectForm(
    isLoadingC,
    isSuccessC,
    isErrorC,
    errorC,
    "Examen creado",
    EXAMEN_LIST_URL,
  );

  useRedirectForm(
    isLoadingE,
    isSuccessE,
    isErrorE,
    errorE,
    "Examen editado",
    EXAMEN_LIST_URL,
  );

  const submitForm = async (values, actions) => {
    try {
      if (!id) {
        await createExamen(values);
      } else {
        const modifiedFields = getModifiedFields(examen, values);
        if (Object.keys(modifiedFields).length !== 0) {
          await editExamen({
            idWrapper: id,
            idInternal: examen.examen.id,
            ...modifiedFields,
          });
        }
      }
      if (isSuccessC || isSuccessE) {
        actions.setSubmitting(false);
        actions.resetForm();
      }
    } catch (error) {
      console.error(error);
      actions.setSubmitting(true);
    }
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
  };

  return (
    <MDBox mt={5} mb={20}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={8}>
          <MDBox mt={6} mb={8} textAlign="center">
            <MDBox mb={1}>
              <MDTypography variant="h3" fontWeight="bold">
                Asignaturas
              </MDTypography>
            </MDBox>
            <MDTypography variant="h5" fontWeight="regular" color="secondary">
              {!id
                ? "Introduzca la información relacionada al examen por agregar"
                : `Edite la información relacionada al examen: ${examen?.examen.nombre}`}
            </MDTypography>
          </MDBox>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, setFieldValue }) => {
              useEffect(() => {
                if (id) {
                  getExamenById(id)
                    .unwrap()
                    .then((res) => {
                      setFieldValue(
                        formField.nombre.name,
                        res?.examen.nombre,
                        true,
                      );
                      setFieldValue(
                        formField.descripcion.name,
                        res?.examen.descripcion ?? "",
                        true,
                      );
                      setFieldValue(
                        formField.anterior.name,
                        res?.anterior.examen.url ?? "",
                      );
                      setFieldValue(
                        formField.estados.name,
                        res?.estados.split(",") ?? [],
                      );
                      setFieldValue(
                        formField.asistencia.name,
                        res?.asistencia,
                        true,
                      );
                      setFieldValue(formField.desfase.name, res?.desfase, true);
                    });
                }
              }, [id]);

              return (
                <ErrorBundary renderOnError={() => <FormErrorHandler />}>
                  <Form id={formId} autoComplete="off">
                    <Card sx={{ height: "100%" }}>
                      <MDBox p={3}>
                        <MDBox>
                          <ExamenInfo
                            formData={{
                              values,
                              touched,
                              formField,
                              setFieldValue,
                              errors,
                            }}
                          />
                          <MDBox
                            mt={2}
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                          >
                            <MDButton
                              onClick={(e) => {
                                navigate(EXAMEN_LIST_URL);
                              }}
                              type="button"
                              variant="gradient"
                              color="primary"
                            >
                              cancelar
                            </MDButton>
                            <MDButton
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              aceptar
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Form>
                </ErrorBundary>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default NewExamenForm;
