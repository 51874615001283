import MDBox from "components/MDBox";
import ModalBase from "components/ModalBase/modal_base";

// Images
import calificaciones from "assets/images/tecnoschool/plantillas-pdf/ejemplo-calificaciones.jpeg";
import boleta from "assets/images/tecnoschool/plantillas-pdf/boleta.jpeg";
import acreditaciones from "assets/images/tecnoschool/plantillas-pdf/acreditacion.jpeg";
import constancia_de_curso from "assets/images/tecnoschool/plantillas-pdf/ejemplo-calificaciones.jpeg";

const ModalPlantillaPDF = ({ open, handleClose, tipo }) => {
  function TipoPlantilla(tipo) {
    switch (tipo) {
      case "calificaciones":
        return calificaciones;
      case "boleta":
        return boleta;
      case "acreditaciones":
        return acreditaciones;
      case "constancia de curso":
        return constancia_de_curso;
    }
  }

  return (
    <ModalBase open={open} handleClose={handleClose}>
      <MDBox
        component="img"
        variant="gradient"
        src={TipoPlantilla(tipo)}
        width="100%"
      />
    </ModalBase>
  );
};

export default ModalPlantillaPDF;
