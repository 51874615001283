import { appendFormData } from "helpers/functions";
import { templatesApi } from "../view/modelos.service";

export const editImagenPlantilla = templatesApi.injectEndpoints({
  endpoints: (builder) => ({
    editImagenPlantilla: builder.mutation({
      query: (data) => {
        const { id, ...rest } = data;
        const formData = new FormData();
        Object.entries(rest).forEach(([key, value]) => {
          appendFormData(formData, value, key);
        });
        return {
          url: `/v1/api/templateimage/${id}/`,
          method: "PATCH",
          body: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
      },
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "Templates", id },
              { type: "Templates", id: "LIST" },
            ]
          : [{ type: "Templates", id: "LIST" }],
    }),
  }),
});

export const { useEditImagenPlantillaMutation } = editImagenPlantilla;
