import { cicloApi } from "../view/ciclo.service";

export const toggleDivision = cicloApi.injectEndpoints({
  endpoints: (builder) => ({
    toggleDivision: builder.mutation({
      query: (id) => {
        return {
          url: `/v1/api/division/${id}/toggle/`,
          method: "POST",
        };
      },
      invalidatesTags: [{ type: "Ciclo", id: "LIST" }],
    }),
  }),
});

export const { useToggleDivisionMutation } = toggleDivision;
