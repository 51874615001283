import { useNavigate } from "react-router-dom";
import { CircularProgress, Icon, Tooltip, useMediaQuery } from "@mui/material";
import React, { useEffect, useMemo, useReducer } from "react";
import useModalHandler from "../../../../../../hooks/useModalHandler";
import {
  useDeleteActaMutation,
  useGetEvaluacionesQuery,
  useGetFechaEvaluacionQuery,
  useLazyGetTemplatesQuery,
  useLazyNotasEstudiantesQuery,
} from "../../../../../../services";
import usePagination from "../../../../../../hooks/usePagination";
import {
  AUXILIAR_DOCENTE,
  COORDINADOR_DOCENTE,
  PROFESOR,
} from "../../../../../../roles";
import ReportesComponent from "../reportes";
import {
  ordenarPorIdentificador,
  ordenarPorNombreCompleto,
} from "../ordinaria/calificaciones.table";
import MDBox from "../../../../../../components/MDBox";
import { style_icon } from "../../../../../../helpers/global_vars";
import ComponentsPrivatization from "../../../../../../config/component_privatization";
import { useRedirectForm } from "../../../../../../hooks/useRedirectForm";
import ModalFechEval from "../../../../../../components/ModalFechaEvaluacion/modalFecha";
import MDTypography from "../../../../../../components/MDTypography";
import FechaDeEvaluaciones from "../compFechaEval";
import DataTable from "../../../../../../examples/Tables/DataTable";
import { FilterGrupo, FiltersComponent } from "../Filters";
import {
  auth_state,
  calificaciones,
  desfaseHandler,
} from "../../../../../../slices";
import { handleToast } from "../../../../../../helpers/handleToast";
import { useDispatch, useSelector } from "react-redux";
import { EXAMEN_CALIFICACION_SHOW_URL } from "../../../../../../config/bases_url";
import { Encoder } from "../../../../../../helpers/encoder";
import { useSnackbar } from "notistack";
import ActaCalificaciones from "layouts/escuela/pdf/reactPdf/acta_calificaciones";
import { pdf } from "@react-pdf/renderer";
import ActaAcreditacion from "layouts/escuela/pdf/reactPdf/acreditacion_acta";
import HistorialComponentCalificaciones from "../historial";
import { reemplazarUrl } from "helpers/functions";

const ACTIONS = {
  SET_FILTERS: "SET_FILTERS",
  SET_DIST_PROF: "SET_DIST_PROF",
};

const initialState = {
  filters: { profesor: "", grupo: "" },
  distProf: {
    id: "",
    examen: "",
    profesor: "",
    asignatura: "",
  },
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_FILTERS:
      return {
        ...state,
        filters: { ...action.payload },
      };
    case ACTIONS.SET_DIST_PROF:
      return {
        ...state,
        distProf: action.payload,
      };
    default:
      return state;
  }
}

// Utility function to map and sort data
const mapAndSortData = (data, mapFn, sortFn) => {
  return data?.map(mapFn).slice().sort(sortFn);
};

// Mapping and sorting functions
const mapProfesor = (e) => {
  const { profesor_id, profesor_name } = e;
  return { profesor_id, profesor_name };
};

const mapGrupo = (e) => {
  const { grupo_id, grupo_ident, grupo_url } = e;
  return { grupo_id, grupo_ident, grupo_url };
};

export default function ExamenEvaluacion({
  tableHeader,
  ciclo: { id: ciclo_id, name: cicle_name, activo, url: ciclo_url, ind },
  examen: {
    id: examenWrapperId,
    name: examenName,
    desfase,
    estadosEstudiantes,
    haveAnterior,
    anteriorName,
    compartido,
    url,
  },
}) {
  const {
    division,
    examenWithDefase: { skip, ciclosInternos, idCiclo, divKey },
  } = useSelector(calificaciones);
  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatchRedux = useDispatch();
  const { authUser } = useSelector(auth_state);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (desfase > 0) {
      dispatchRedux(desfaseHandler({ desfase, ind }));
    }
  }, [tableHeader, desfase, division]);

  //Props del modal de fecha evaluacion
  const {
    open: openFechaEval,
    handleOpen: handleOpenFechaEval,
    handleClose: handleCloseFechaEval,
  } = useModalHandler();

  const {
    busqueda,
    setBusqueda,
    offset,
    setOffset,
    limit,
    handleNext,
    handlePrevious,
  } = usePagination();

  const {
    data: data,
    isLoading: isLoadingG,
    isFetching,
  } = useGetEvaluacionesQuery(
    {
      filter_params: {
        ...(haveAnterior ? { aprobado: "false" } : {}),
        buscar: busqueda,
        distribucion_profesor__distribucion_grupo__ciclo:
          desfase === 0 ? ciclo_id : idCiclo,
        ciclos_internos:
          desfase === 0
            ? division?.ciclos_internos?.join(",")
            : ciclosInternos?.join(","),
        distribuciones_de_profesores_distintas: "true",
        limit: limit,
        offset: offset,
        examen: examenWrapperId,
        query:
          "{distribucion_profesor{id,actas,profesor{id,url,user{first_name,apellido_materno,apellido_paterno}},asignatura{nombre,url,clave},distribucion_grupo{ciclo_interno{numero_de_ciclo,carrera{nombre}},id,url,grupo{identificador}}}}",
        ...(state.filters.grupo !== "" && {
          distribucion_profesor__distribucion_grupo: state.filters.grupo,
        }),
        ...(authUser?.groups?.some((e) => e.name === PROFESOR) && {
          distribucion_profesor__profesor: authUser?.perfil.resourcetype?.find(
            (e) => e.resourcetype === PROFESOR
          ).id,
        }),
        ...(state.filters.profesor !== "" && {
          distribucion_profesor__profesor: state.filters.profesor,
        }),
      },
      shape: {
        id: "distribucion_profesor.id",
        semestre:
          "distribucion_profesor.distribucion_grupo.ciclo_interno.numero_de_ciclo",
        grupo_ident:
          "distribucion_profesor.distribucion_grupo.grupo.identificador",
        grupo_id: "distribucion_profesor.distribucion_grupo.id",
        grupo_url: "distribucion_profesor.distribucion_grupo.url",
        asignatura: "distribucion_profesor.asignatura.nombre",
        asignatura_clave: "distribucion_profesor.asignatura.clave",
        actas: "distribucion_profesor.actas",
        profesor_name:
          "distribucion_profesor.profesor.user.apellido_paterno distribucion_profesor.profesor.user.apellido_materno distribucion_profesor.profesor.user.first_name",
        profesor_id: "distribucion_profesor.profesor.id",
        asignatura_url: "distribucion_profesor.asignatura.url",
        dist_grupo_url: "distribucion_profesor.distribucion_grupo.url",
        profesor_url: "distribucion_profesor.profesor.url",
        carrera:
          "distribucion_profesor.distribucion_grupo.ciclo_interno.carrera.nombre",
      },
    },
    {
      skip,
      refetchOnReconnect: true,
    }
  );

  const { data: fechas, isLoading: isLoadingF } = useGetFechaEvaluacionQuery({
    filter_params: {
      examen__nombre: examenName,
      query: "{id,inicio,fin,profesor{id},abierto,accesible,examen{nombre}}",
      division_numero: desfase === 0 ? division?.division : divKey,
      profesor__distribucion_grupo__ciclo: ciclo_id,
    },
  });

  const [borrarActa, { isSuccess, isLoading, isError, error }] =
    useDeleteActaMutation();

  const [getPlantillaPDF] = useLazyGetTemplatesQuery();

  const [getNotas] = useLazyNotasEstudiantesQuery();

  //constantes para manejar la paginacion
  const dataRest = data?.results ? data.results : data;
  const countTotal = data?.count;
  const previous = data?.previous;
  const next = data?.next;

  const profesoresOrdenados = mapAndSortData(
    dataRest,
    mapProfesor,
    ordenarPorNombreCompleto
  );
  const gruposOrdenados = mapAndSortData(
    dataRest,
    mapGrupo,
    ordenarPorIdentificador
  );

  const dataTable = {
    columns: [
      {
        Header: "Semestre",
        accessor: "semestre",
        width: "10%",
      },
      {
        Header: "Grupo",
        accessor: "grupo_ident",
      },
      {
        Header: "Clave de Asignatura",
        accessor: "asignatura_clave",
      },
      {
        Header: "Asignatura",
        accessor: "asignatura",
      },
      {
        Header: "Profesor",
        accessor: "profesor_name",
      },
      {
        Header: "Acciones",
        accessor: "actions",
        width: "10%",
        Cell: ({ row }) => {
          const { original } = row;
          const acta_actual = original.actas.find(
            (el) => el.examen.nombre === examenName
          );

          return (
            <MDBox display="flex">
              <MDBox px={0.2}>
                <Tooltip
                  placement="top"
                  title="Ver toda la información de la evaluación en vista de detalles (Puede acceder a esta funcionalidad con doble click en cualquier parte de la fila)"
                >
                  <Icon
                    style={style_icon}
                    onClick={() => handleClickShow(original)}
                    fontSize="small"
                  >
                    find_in_page
                  </Icon>
                </Tooltip>
              </MDBox>
              {acta_actual && (
                <>
                  {compartido && (
                    <>
                      <ComponentsPrivatization
                        permitted={[COORDINADOR_DOCENTE, AUXILIAR_DOCENTE]}
                      >
                        <MDBox px={0.2}>
                          <Tooltip
                            placement="top"
                            title="Ver acta de la evaluación sin firmar"
                          >
                            <Icon
                              style={style_icon}
                              onClick={() => handleClickActa(original)}
                              fontSize="small"
                              color="success"
                            >
                              picture_as_pdf
                            </Icon>
                          </Tooltip>
                        </MDBox>
                        {examenName === "Ordinario" && (
                          <MDBox px={0.2}>
                            <Tooltip
                              placement="top"
                              title="Ver acta de acreditación"
                            >
                              <Icon
                                style={style_icon}
                                fontSize="small"
                                onClick={() => printPDF(original, cicle_name)}
                              >
                                book
                              </Icon>
                            </Tooltip>
                          </MDBox>
                        )}
                      </ComponentsPrivatization>
                    </>
                  )}
                  <ComponentsPrivatization
                    permitted={[
                      COORDINADOR_DOCENTE,
                      PROFESOR,
                      AUXILIAR_DOCENTE,
                    ]}
                  >
                    <MDBox px={0.2}>
                      <Tooltip
                        placement="top"
                        title="Ver acta de la evaluación firmada"
                      >
                        <Icon
                          style={style_icon}
                          onClick={() => handleClickNavigate(acta_actual.acta)}
                          fontSize="small"
                          color="error"
                        >
                          picture_as_pdf
                        </Icon>
                      </Tooltip>
                    </MDBox>
                  </ComponentsPrivatization>

                  {activo && (
                    <ComponentsPrivatization permitted={[COORDINADOR_DOCENTE]}>
                      <MDBox px={0.2}>
                        <Tooltip
                          placement="top"
                          title="Eliminar acta firmada de la base de datos"
                        >
                          <Icon
                            style={style_icon}
                            onClick={() =>
                              handleToast({
                                title: "¿Estás seguro?",
                                text: `¡Si eliminas el acta, la acción no se podrá revertir!`,
                                dispatcher: (result) => {
                                  if (result.isConfirmed) {
                                    try {
                                      borrarActa({
                                        id: acta_actual.id,
                                      });
                                    } catch (error) {
                                      console.log(error);
                                    }
                                  }
                                },
                              })
                            }
                            fontSize="small"
                          >
                            remove_circle
                          </Icon>
                        </Tooltip>
                      </MDBox>
                    </ComponentsPrivatization>
                  )}
                </>
              )}
              {fechas?.length !== 0 && activo && (
                <ComponentsPrivatization permitted={COORDINADOR_DOCENTE}>
                  <MDBox px={0.2}>
                    <Tooltip
                      placement="top"
                      title="Cambiar la fecha de evaluación de esta asignatura"
                    >
                      <Icon
                        style={style_icon}
                        onClick={() => handlePermisoEvaluacion(original)}
                        fontSize="small"
                      >
                        access_time
                      </Icon>
                    </Tooltip>
                  </MDBox>
                </ComponentsPrivatization>
              )}
            </MDBox>
          );
        },
      },
    ],

    rows: dataRest ?? [],
  };

  useRedirectForm(
    isLoading,
    isSuccess,
    isError,
    error,
    "Acta eliminada",
    `/ciclos/${cicle_name}/calificaciones`
  );

  const handleClickShow = (el) => {
    navigate(
      EXAMEN_CALIFICACION_SHOW_URL(
        cicle_name,
        Encoder.encodeObjectToBase64({
          profesor: el.id,
          division: division.division_verbose,
          examen: {
            name: examenName,
            estados: estadosEstudiantes,
            anteriorName,
            url: url,
          },
          grupo: el.grupo_ident,
        })
      )
    );
  };

  const getValores = async ({ plantilla: { tipo }, notas: { id } }) => {
    const [plantilla, notas] = await Promise.all([
      getPlantillaPDF({
        filter_params: {
          tipo: tipo,
        },
      }),
      getNotas({
        id: id,
        filter_params: {
          query:
            "{estudiante{id,url,distribucion_grupo{grupo{identificador}}, estudiante{numero_de_control, user{first_name, apellido_paterno, apellido_materno}}}, notas{id,nota_final,aprobado,asistencias,porcentaje_de_asistencia,faltas,examen{nombre}}}",
        },
      }),
    ]);
    if (
      !plantilla.data[0].imagenes.find((e) => e.espacio === "header").imagen
    ) {
      enqueueSnackbar(
        `Para mostrar el acta es necesario que actualice su modelo-pdf de ${tipo}`,
        {
          variant: "warning",
          autoHideDuration: 5000,
        }
      );
      return;
    }
    return [plantilla, notas];
  };

  const handleClickActa = async (e) => {
    try {
      const [plantilla, notas] = await getValores({
        plantilla: { tipo: "calificaciones" },
        notas: {
          id: e.id,
        },
      });
      let obj = pdf(
        <ActaCalificaciones
          info={e}
          cicle_name={cicle_name}
          tipo_examen={examenName}
          plantilla={plantilla?.data[0].imagenes}
          notas={notas.data}
        />
      ).toBlob();
      obj.then(function (blob) {
        let url = URL.createObjectURL(blob);
        window.open(url, "_blank");
      });
    } catch (error) {
      enqueueSnackbar(`Hubo algún error generando el acta`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const printPDF = async (original) => {
    try {
      const [plantilla, notas] = await getValores({
        plantilla: { tipo: "acreditaciones" },
        notas: {
          id: original.id,
        },
      });
      let obj = pdf(
        <ActaAcreditacion
          info={original}
          cicle_name={cicle_name}
          notas={notas.data}
          plantilla={plantilla?.data[0].imagenes}
        />
      ).toBlob();
      obj.then(function (blob) {
        let url = URL.createObjectURL(blob);
        window.open(url, "_blank");
      });
    } catch (error) {
      enqueueSnackbar(`Hubo algún error generando el acta`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const handleClickNavigate = (el) => {
    window.open(el, "_blank");
  };

  const handlePermisoEvaluacion = (original) => {
    console.log(original);
    dispatch({
      type: ACTIONS.SET_DIST_PROF,
      payload: {
        id: original.id,
        examen: examenName,
        profesor: original.profesor_name,
        asignatura: original.asignatura,
      },
    });
    handleOpenFechaEval();
  };

  const handleChange = (e) => {
    dispatch({
      type: ACTIONS.SET_FILTERS,
      payload: { [e.target.name]: e.target.value },
    });
  };

  return (
    <>
      <ModalFechEval
        open={openFechaEval}
        handleClose={handleCloseFechaEval}
        distribucion_profesor={state.distProf}
      />

      <MDBox
        display={isSmallScreen ? "flex" : "flex"}
        flexDirection={isSmallScreen ? "column" : "row"}
        justifyContent={"space-between"}
        alignItems={"baseline"}
        m={2}
        gap={2}
        p={3}
      >
        <MDTypography variant="h5" fontWeight="medium" mb={1}>
          {tableHeader}:
        </MDTypography>

        <ComponentsPrivatization
          permitted={[COORDINADOR_DOCENTE, AUXILIAR_DOCENTE]}
        >
          {compartido &&
            (examenName.includes("Regularización") ? (
              <ReportesComponent
                examen={examenName}
                wrapper={examenWrapperId}
                ciclo={ciclo_id}
                divKey={desfase === 0 ? division.division : divKey}
              />
            ) : (
              <HistorialComponentCalificaciones
                examen={{ url: url, nombre: examenName }}
                grupos={FilterGrupo(gruposOrdenados)?.map((e) =>
                  reemplazarUrl(e.grupo_url, 1)
                )}
              />
            ))}
        </ComponentsPrivatization>
      </MDBox>

      <MDBox px={3}>
        {fechas && (
          <FechaDeEvaluaciones
            fechas={fechas}
            ciclo_id={ciclo_id}
            examen={examenName}
            examenURL={url}
            division={desfase === 0 ? division.division : divKey}
            ciclo_url={ciclo_url}
          />
        )}
      </MDBox>

      <MDBox display="flex" justifyContent={"center"} alignItems={"center"}>
        {isLoadingG || isFetching ? (
          <MDBox
            width={"100%"}
            display={"flex"}
            py={5}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress sx={{ marginBottom: "3rem" }} color="inherit" />
          </MDBox>
        ) : (
          <DataTable
            table={dataTable}
            canSearch
            doubleClick={handleClickShow}
            paginationApi
            countTotal={countTotal}
            next={next}
            previous={previous}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            setBusqueda={setBusqueda}
            offset={offset}
            setOffset={setOffset}
            filtersComponent={
              <FiltersComponent
                values={state.filters}
                handleChange={handleChange}
                profesores={profesoresOrdenados}
                grupos={gruposOrdenados}
              />
            }
          />
        )}
      </MDBox>
    </>
  );
}
