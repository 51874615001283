import { buildFiltersUrl } from "helpers/functions";
import { templatesApi } from "../view/modelos.service";

export const getTemplates = templatesApi.injectEndpoints({
  endpoints: (builder) => ({
    getTemplates: builder.query({
      query: ({ filter_params }) => ({
        url: buildFiltersUrl("/v1/api/template/", filter_params),
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Templates",
                id,
              })),
              { type: "Templates", id: "LIST" },
            ]
          : [{ type: "Templates", id: "LIST" }],
    }),
  }),
});

export const { useGetTemplatesQuery, useLazyGetTemplatesQuery } = getTemplates;
