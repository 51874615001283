/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================
 
 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)
 
 Coded by www.creative-tim.com
 
 =========================================================
 
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// formik components
import { Form, Formik } from "formik";

//React Hooks and Others
import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, Icon, Tooltip } from "@mui/material";

import MDImage from "components/MDImage/image";
import { MODELOS_LIST_URL } from "config/bases_url";
import ModalPlantillaPDF from "components/ModalPlantillaPDF/modal-plantillas-pdf";
import useModalHandler from "hooks/useModalHandler";
import { ErrorBundary } from "config/ErrorBundary/error-bundary";
import FormErrorHandler from "handlers/FormErrorHandler/form-error.handler";
import { useSnackbar } from "notistack";
import {
  useGetTemplatesByIdQuery,
  useEditImagenPlantillaMutation,
} from "services";
import { useRedirectForm } from "hooks/useRedirectForm";
import { getModifiedFields } from "helpers/functions";
import * as Yup from "yup";

const style = {
  width: "100%",
  cursor: "pointer",
  borderRadius: "0.5rem",
  marginTop: "0.5rem",
  marginLeft: "0.5rem",
};

const icon_style = {
  position: "absolute",
  left: "50%",
  top: "50%",
  fontSize: "30px",
  transform: "translate(-50%,-50%)",
};

function EditarModeloPDF() {
  const { enqueueSnackbar } = useSnackbar();

  const [header, setHeader] = useState(null);
  const [footer, setFooter] = useState(null);

  const { open, handleOpen, handleClose } = useModalHandler();

  const { id, tipo } = useParams();

  const { data: plantilla } = useGetTemplatesByIdQuery(id);

  const [
    editImagenPlantilla,
    {
      isSuccess: isSuccessE,
      isLoading: isLoadingE,
      isError: isErrorE,
      error: errorE,
    },
  ] = useEditImagenPlantillaMutation();

  useRedirectForm(
    isLoadingE,
    isSuccessE,
    isErrorE,
    errorE,
    "Modelo de plantilla pdf editada con éxito",
    MODELOS_LIST_URL
  );

  const handleClick = () => {
    handleOpen();
  };

  const submitForm = async (values, actions) => {
    const originalValues = { cabecera: header.imagen, pie: footer.imagen };
    try {
      const modifiedFields = getModifiedFields(originalValues, values);
      if (Object.keys(modifiedFields).length !== 0) {
        // verificamos que hubo cambios en los valores del formulario con respecto a los valores iniciales
        for (const propiedad in modifiedFields) {
          // si hubo cambios tomamos el key del objeto original
          await editImagenPlantilla(
            propiedad === "cabecera"
              ? { id: header.id, imagen: values.cabecera }
              : { id: footer.id, imagen: values.pie }
          );
        }
      }

      actions.setSubmitting(false);
      actions.resetForm();
    } catch (error) {
      console.error(error);
      actions.setSubmitting(true);
    }
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
  };

  return (
    <MDBox pt={6} pb={3}>
      <ModalPlantillaPDF open={open} handleClose={handleClose} tipo={tipo} />
      <Card>
        <MDBox
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginX={4}
          mt={2}
        >
          <Link to={MODELOS_LIST_URL}>
            <MDButton variant="gradient" color="info" size="medium">
              Atras
            </MDButton>
          </Link>

          <MDButton
            variant="gradient"
            color="info"
            size="medium"
            onClick={handleClick}
          >
            Ver ejemplo
          </MDButton>
        </MDBox>
        {plantilla ? (
          <Formik
            initialValues={{ cabecera: "", pie: "" }}
            validationSchema={Yup.object().shape({
              cabecera: Yup.mixed().required(
                "Debe proporcionar una imagen para la cabecera"
              ),
              pie: Yup.mixed().nullable(),
            })}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => {
              const handleChangeFoto = async (e, field) => {
                const file = e.target.files[0];
                const maxSize = 250 * 1024; // 250 KB

                if (file.size > maxSize) {
                  enqueueSnackbar(
                    "El tamaño de la imagen no debe ser mayor a 250 KB",
                    {
                      variant: "error",
                      autoHideDuration: 4000,
                    }
                  );
                  return;
                } else {
                  setFieldValue(field, e.target.files[0]);
                }
              };

              useEffect(() => {
                if (plantilla) {
                  const cabeceraHeader = plantilla?.imagenes.find(
                    (e) => e.espacio === "header"
                  ); // guardamos el objeto de la cabecera completo para sacar la imagen y el id si es necesario para editar
                  const pieFooter = plantilla?.imagenes.find(
                    (e) => e.espacio === "footer"
                  );
                  setHeader(cabeceraHeader);
                  setFooter(pieFooter);
                  setFieldValue("cabecera", cabeceraHeader.imagen);
                  setFieldValue("pie", pieFooter.imagen);
                }
              }, [plantilla]);
              return (
                <ErrorBundary renderOnError={() => <FormErrorHandler />}>
                  <Form id={"modelos-pdf"} autoComplete="off">
                    <Grid
                      container
                      spacing={3}
                      display="flex"
                      justifyContent={"center"}
                      mb={4}
                    >
                      <Grid item xs={12} mt={4}>
                        <MDBox
                          display="flex"
                          flexDirection={{ xs: "column", sm: "row" }}
                          justifyContent={"space-evenly"}
                          alignItems={"center"}
                          style={{ borderColor: "blue", borderStyle: "solid" }}
                          marginX={2}
                        >
                          {!values.cabecera && (
                            <MDBox mx={1} flex={1}>
                              <MDTypography
                                fontWeight="light"
                                fontSize={"0.75rem"}
                                flex
                                justifyContent="left"
                                textAlign="left"
                                ml={2}
                              >
                                <b>
                                  Espacio que ocupa el recuadro azul superior:
                                </b>{" "}
                                <br />
                                <b>Ancho:</b> 19,72 cm <br /> <b>Alto:</b> 2 cm{" "}
                                <br />
                                <b>Descripción:</b> La imagen que suba debe ser
                                menor a las dimensiones anteriores, tener en
                                cuenta que debe tener menos de 200 KB la foto.
                                Si quiere ver un ejemplo vea: (Botón Ver Ejemplo
                                imagen superior).
                              </MDTypography>
                            </MDBox>
                          )}
                          <MDBox mx={1} flex={1}>
                            <MDTypography
                              variant="h6"
                              fontWeight="bold"
                              flex
                              justifyContent="center"
                              textAlign="center"
                              mt={1}
                            >
                              Imagen de la Cabecera:
                            </MDTypography>
                            <MDBox
                              mt={1.625}
                              display="flex"
                              flexDirection={"column"}
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Tooltip
                                placement="top"
                                title={
                                  !values.cabecera
                                    ? "Agregar imagen"
                                    : "Cambiar Foto"
                                }
                              >
                                <MDBox
                                  style={{
                                    position: "relative",
                                    width: !values.cabecera ? "200px" : "",
                                    cursor: "pointer",
                                  }}
                                >
                                  {!values.cabecera ? (
                                    <Icon fontSize="medium" style={icon_style}>
                                      image_search
                                    </Icon>
                                  ) : null}
                                  <MDImage
                                    id="header-image"
                                    htmlFor="header-image"
                                    disabled={false}
                                    style={style}
                                    src={
                                      values.cabecera &&
                                      typeof values.cabecera === "object"
                                        ? URL.createObjectURL(values.cabecera)
                                        : values.cabecera
                                    }
                                    imageTypes={"image/jpeg, image/png,"}
                                    handleChange={(e) =>
                                      handleChangeFoto(e, "cabecera")
                                    }
                                  />
                                </MDBox>
                              </Tooltip>
                              {values.cabecera && (
                                <Icon
                                  fontSize="medium"
                                  style={{
                                    marginBottom: "5px",
                                    color: "red",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    setFieldValue("cabecera", "", true)
                                  }
                                >
                                  delete
                                </Icon>
                              )}
                            </MDBox>
                          </MDBox>

                          {!values.cabecera && (
                            <MDBox mx={1} flex={1}>
                              <MDTypography
                                fontWeight="light"
                                fontSize={"0.75rem"}
                                flex
                                justifyContent="left"
                                textAlign="left"
                              >
                                <b>Esta imagen es obligatoria.</b> <br /> En el
                                ejemplo (Botón Ver Ejemplo) la foto debe ser el
                                contenido del rectángulo azul superior.
                              </MDTypography>
                            </MDBox>
                          )}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} mt={4}>
                        <MDBox
                          display="flex"
                          flexDirection={{ xs: "column", sm: "row" }}
                          justifyContent={"space-evenly"}
                          alignItems={"center"}
                          style={{ borderColor: "blue", borderStyle: "solid" }}
                          marginX={2}
                        >
                          {!values.pie && (
                            <MDBox mx={1} flex={1}>
                              <MDTypography
                                fontWeight="light"
                                fontSize={"0.75rem"}
                                flex
                                justifyContent="left"
                                textAlign="left"
                                ml={2}
                              >
                                <b>
                                  Espacio que ocupa el recuadro azul inferior:
                                </b>{" "}
                                <br />
                                <b>Ancho:</b> 19,72 cm <br /> <b>Alto:</b> 1,5
                                cm <br />
                                <b>Descripción:</b> La imagen que suba debe ser
                                menor a las dimensiones anteriores, tener en
                                cuenta que debe tener menos de 200 KB la foto.
                                Si quiere ver un ejemplo vea: (Botón Ver Ejemplo
                                imagen superior).
                              </MDTypography>
                            </MDBox>
                          )}
                          <MDBox mx={1} flex={1}>
                            <MDTypography
                              variant="h6"
                              fontWeight="bold"
                              flex
                              justifyContent="center"
                              textAlign="center"
                              mt={1}
                            >
                              Imagen del Fondo:
                            </MDTypography>
                            <MDBox
                              mt={1.625}
                              display="flex"
                              flexDirection={"column"}
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Tooltip
                                placement="top"
                                title={
                                  !values.pie
                                    ? "Agregar imagen"
                                    : "Cambiar Foto"
                                }
                              >
                                <MDBox
                                  style={{
                                    position: "relative",
                                    width: !values.pie ? "200px" : "",
                                    cursor: "pointer",
                                  }}
                                >
                                  {!values.pie ? (
                                    <Icon fontSize="medium" style={icon_style}>
                                      image_search
                                    </Icon>
                                  ) : null}
                                  <MDImage
                                    id="footer-image"
                                    htmlFor="footer-image"
                                    disabled={false}
                                    style={style}
                                    src={
                                      values.pie &&
                                      typeof values.pie === "object"
                                        ? URL.createObjectURL(values.pie)
                                        : values.pie
                                    }
                                    imageTypes={"image/jpeg, image/png,"}
                                    handleChange={(e) =>
                                      handleChangeFoto(e, "pie")
                                    }
                                  />
                                </MDBox>
                              </Tooltip>
                              {values.pie && (
                                <Icon
                                  fontSize="medium"
                                  style={{
                                    marginBottom: "5px",
                                    color: "red",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => setFieldValue("pie", "", true)}
                                >
                                  delete
                                </Icon>
                              )}
                            </MDBox>
                          </MDBox>
                          {!values.pie && (
                            <MDBox mx={1} flex={1}>
                              <MDTypography
                                fontWeight="light"
                                fontSize={"0.75rem"}
                                flex
                                justifyContent="left"
                                textAlign="left"
                              >
                                <b>Esta imagen no es obligatoria.</b> <br /> En
                                el ejemplo queda un espacio en blanco si quiere
                                puede dejarlo así, (Botón Ver Ejemplo) la foto
                                debe ser el contenido del rectángulo azul
                                inferior.
                              </MDTypography>
                            </MDBox>
                          )}
                        </MDBox>
                      </Grid>
                    </Grid>
                    <MDBox
                      mt={2}
                      display="flex"
                      justifyContent="flex-end"
                      mr={4}
                      mb={4}
                    >
                      <MDButton type="submit" variant="gradient" color="dark">
                        aceptar
                      </MDButton>
                    </MDBox>
                  </Form>
                </ErrorBundary>
              );
            }}
          </Formik>
        ) : (
          <MDBox
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent={"center"}
            alignItems={"center"}
            paddingY={4}
          >
            <CircularProgress size={"60px"} margin="auto" />
          </MDBox>
        )}
      </Card>
    </MDBox>
  );
}

export default EditarModeloPDF;
