export class Encoder {
  static encodeObjectToBase64(obj) {
    // Hacemos una copia profunda del objeto por seguridad
    const clonedObject = _.cloneDeep(obj);

    const jsonString = JSON.stringify(clonedObject);
    const base64String = btoa(jsonString);

    return base64String;
  }

  static decodeBase64ToObject(base64String) {
    const jsonString = atob(base64String);
    const obj = JSON.parse(jsonString);

    return obj;
  }
}
