import { templatesApi } from "../view/modelos.service";
import { buildFiltersUrl } from "../../../../helpers/functions";

export const getTemplatesImage = templatesApi.injectEndpoints({
  endpoints: (builder) => ({
    getTemplatesImage: builder.query({
      query: ({ filterParams }) => ({
        url: buildFiltersUrl("/v1/api/templateimage/", filterParams),
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Templates",
                id,
              })),
              { type: "Templates", id: "LIST" },
            ]
          : [{ type: "Templates", id: "LIST" }],
    }),
  }),
});

export const { useGetTemplatesImageQuery } = getTemplatesImage;
