import { createSlice } from "@reduxjs/toolkit";
import { getCicloById, getCiclos, getExamenes } from "../../services";
import { enqueueSnackbar } from "notistack";
import _ from "lodash";

const initialState = {
  tab: "",
  carreraSelect: {},
  cicloActivo: "",
  division: null,
  divisionesIndexada: null,
  loading: false,
  ciclos: [],
  examenWithDefase: {
    ciclosInternos: [],
    skip: false,
    idCiclo: null,
    divKey: null,
  },
};

const updateDivisionesManager = (state, payload) => {
  const divisiones = Array.isArray(payload)
    ? payload[0].divisiones
    : payload.divisiones;

  state.divisionesIndexada = divisiones.reduce((acc, el) => {
    acc[el.division] = el;
    return acc;
  }, {});

  state.division = divisiones.find((e) => !e.cerrada) ?? divisiones[0];
  state.loading = false;
};

export const calificacionesSlice = createSlice({
  name: "calificaciones",
  initialState,
  reducers: {
    setTab: (state, action) => {
      state.tab = action.payload;
    },
    setCarreraSelect: (state, { payload: { carrera, updateDivision } }) => {
      state.carreraSelect = carrera;
      if (updateDivision) {
        updateDivisionesManager(state, carrera);
      }
    },
    setCicloActivo: (state, action) => {
      state.cicloActivo = action.payload;
    },
    setLoadingCalif: (state, action) => {
      state.loading = action.payload;
    },
    setDivision: (state, action) => {
      state.division = action.payload;
    },
    examenWithDefaseCleaner: (state) => {
      state.examenWithDefase = initialState.examenWithDefase;
    },
    desfaseHandler: (state, { payload: { desfase, ind } }) => {
      if (
        !_.isEmpty(state.division) &&
        state.divisionesIndexada &&
        desfase !== 0
      ) {
        state.loading = true;
        const { division: divisionKey } = state.division;
        const esPrimeraDivision = parseInt(divisionKey) === 1;
        const ultimaDivisionKey = _.size(state.divisionesIndexada);

        /*
         * Se accede a la división correspondiente según el desfase del examen seleccionado,
         * si es la primera división se accede a la última división restandole el desfase y
         * sumandole 1 ya que ya se viró un paso atras
         */
        state.examenWithDefase.ciclosInternos =
          ind === 0 && esPrimeraDivision
            ? []
            : _.get(
                state.divisionesIndexada,
                [
                  esPrimeraDivision
                    ? ultimaDivisionKey - desfase + 1
                    : divisionKey - desfase,
                  "ciclos_internos",
                ],
                [],
              );

        state.examenWithDefase.divKey =
          ind === 0 && esPrimeraDivision
            ? []
            : _.get(
                state.divisionesIndexada,
                [
                  esPrimeraDivision
                    ? ultimaDivisionKey - desfase + 1
                    : divisionKey - desfase,
                  "division",
                ],
                [],
              );

        /*
         * Actualiza al nuevo id del ciclo correspondiente segun el desafse del examen
         */
        const posicionCalculada =
          ind -
          Math.floor(desfase / ultimaDivisionKey) -
          (divisionKey <= desfase ? 1 : 0);
        state.examenWithDefase.idCiclo = state.ciclos[posicionCalculada]?.id;

        /*
         * Actualiza el state de skip para no realizar la peticion en caso de q sea la primera
         * division ya q no hay datos para cargar ua asignatura con desfase
         */
        state.examenWithDefase.skip =
          ind === 0 && esPrimeraDivision && posicionCalculada < 0;
        state.loading = false;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => {
        if (
          getExamenes.endpoints.getExamenes.matchFulfilled(action) &&
          action.meta &&
          action.meta.arg
        ) {
          return (
            !action.meta.arg.originalArgs.filter_params?.offset &&
            !action.meta.arg.originalArgs.filter_params?.limit
          );
        }
        return false;
      },

      (state, action) => {
        state.tab = String(action.payload[0].nombre).toLowerCase();
      },
    );
    builder.addMatcher(
      getCiclos.endpoints.getCiclos.matchFulfilled,
      (state, action) => {
        state.ciclos = action.payload;
      },
    );
    builder.addMatcher(
      getCicloById.endpoints.getCicloById.matchFulfilled,
      (state, action) => {
        if (action.payload.length === 0) {
          enqueueSnackbar(
            "Debe hacer todo el proceso de asignar alumnos, pofesores y asignaturas a un grupo para poder ver sus calificaciones",
            {
              variant: "error",
              autoHideDuration: 5000,
            },
          );
        } else {
          state.carreraSelect = action.payload.carreras[0];
          updateDivisionesManager(state, action.payload.carreras);
        }
      },
    );
  },
});

export const {
  examenWithDefaseCleaner,
  desfaseHandler,
  setTab,
  setCarreraSelect,
  setCicloActivo,
  setDivision,
  setLoadingCalif,
} = calificacionesSlice.actions;

export const calificaciones = (state) => state.calificaciones;

export default calificacionesSlice.reducer;
