import { CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useLazyGetTaskQuery, useMakeReporteMutation } from "services";
import { useSelector } from "react-redux";
import { calificaciones } from "../../../../../slices";

const ReportesComponent = ({ examen, wrapper, ciclo, divKey }) => {
  const { division } = useSelector(calificaciones);
  const [execute] = useMakeReporteMutation();
  const [triggerTarea] = useLazyGetTaskQuery();
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleReporte = () => {
    execute({
      filter_params: {
        query: "{id}",
      },
      body: {
        ciclo: ciclo,
        examen_entidad: wrapper,
        numero_division: divKey,
      },
      skip: false,
    })
      .unwrap()
      .then((res) => {
        if (res && res.id) {
          peticionTarea(res.id);
        }
      });
  };

  const peticionTarea = async (id) => {
    setLoading(true);
    triggerTarea(id)
      .unwrap()
      .then((res) => {
        if (res.estado === "COMPLETED") {
          descargarCSV(res.resultado);
          enqueueSnackbar("Reporte generado correctamente", {
            variant: "success",
            autoHideDuration: 8000,
          });
        } else if (res.estado === "ERROR") {
          setLoading(false);
          enqueueSnackbar("Ocurrió un problema al generar el reporte", {
            variant: "error",
            autoHideDuration: 8000,
          });
        } else {
          setTimeout(() => {
            peticionTarea(id);
          }, [10000]);
        }
      });
  };

  const descargarCSV = (resultado) => {
    setLoading(false);
    const csvContent = "\uFEFF" + resultado;
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `Reporte ${examen}, ${division.division_verbose}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <MDBox>
      <MDButton
        disabled={loading}
        variant={"gradient"}
        color={"secondary"}
        size="medium"
        onClick={handleReporte}
        loading={loading}
      >
        Reporte
      </MDButton>
    </MDBox>
  );
};

export default ReportesComponent;
