import { cicloApi } from "../view/ciclo.service";

export const cerrarCiclo = cicloApi.injectEndpoints({
  endpoints: (builder) => ({
    cerrarCiclo: builder.mutation({
      query: (id) => ({
        url: `/v1/api/ciclo/close/`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Ciclo", id: "LIST" }],
    }),
  }),
});

export const { useCerrarCicloMutation } = cerrarCiclo;
