import { cicloApi } from "../view/ciclo.service";
import {
  buildFiltersUrl,
  reshape_response,
} from "../../../../helpers/functions";

export const getCicloById = cicloApi.injectEndpoints({
  endpoints: (builder) => ({
    getCicloById: builder.query({
      query: (args) => {
        let url = `/v1/api/ciclo/${args.id}/`;

        return {
          url: !args.filter_params
            ? url
            : buildFiltersUrl(url, args.filter_params),
          method: "GET",
        };
      },
      providesTags: (result, error, id) => [{ type: "Ciclo", id }],
      transformResponse: (response, meta, args) => {
        if (!args?.shape || !args) return response;

        return reshape_response(response, args.shape);
      },
    }),
  }),
});

export const { useGetCicloByIdQuery, useLazyGetCicloByIdQuery } = getCicloById;
