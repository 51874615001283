import { appendFormData } from "helpers/functions";
import { templatesApi } from "../view/modelos.service";

export const createImagenPlantilla = templatesApi.injectEndpoints({
  endpoints: (builder) => ({
    createImagenPlantilla: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
          appendFormData(formData, value, key);
        });
        return {
          url: "/v1/api/templateimage/",
          method: "POST",
          body: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
      },
      invalidatesTags: [{ type: "Templates", id: "LIST" }],
    }),
  }),
});

export const { useCreateImagenPlantillaMutation } = createImagenPlantilla;
