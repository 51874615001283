import _ from "lodash";

export const toIsoDate = (isoDate, onlyYear = false) => {
  const date = new Date(isoDate);
  if (isNaN(date)) return "2000";

  return new Intl.DateTimeFormat(
    "es-ES",
    onlyYear
      ? { year: "numeric" }
      : {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }
  ).format(date);
};

export const buildFiltersUrl = (base, params) => {
  const query = new URLSearchParams(_.pickBy(params)).toString();
  return `${base}?${query}`;
};

export const checkPerm = (permitted) => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  return _.isArray(permitted)
    ? _.some(user?.groups, (role) => permitted.includes(role.name))
    : _.isString(permitted)
      ? _.some(user?.groups, (role) => role.name === permitted)
      : user?.is_staff;
};

// Tranformar la respuesta final a una forma especifica pasando un objeto shape con las configuraciones pertinentes
export function reshape_response(response, shape) {
  function reshape(response, shape) {
    return Object.keys(shape).reduce((reshapedData, key) => {
      const transformer = shape[key];
      reshapedData[key] = resolvePath(response, transformer);
      return reshapedData;
    }, {});
  }

  function resolvePath(response, path) {
    if (typeof path === "function") {
      return path(response);
    } else if (path.includes(" ")) {
      const parts = path.split(" ");
      return parts.map((part) => resolveSinglePath(response, part)).join(" ");
    } else {
      return resolveSinglePath(response, path);
    }
  }

  function resolveSinglePath(response, path) {
    const arrayRegex = /\[\d*\]/;
    const pathSegments = path.split(".");

    let currentObject = response;
    for (let i = 0; i < pathSegments.length; i++) {
      const segment = pathSegments[i];

      if (segment.match(arrayRegex)) {
        const [base, index] = segment.split(/[\[\]]/).filter(Boolean);

        if (base) {
          currentObject = currentObject ? currentObject[base] : undefined;
        }

        if (currentObject === undefined) {
          return null;
        }

        if (index !== undefined && index !== "") {
          currentObject = currentObject[Number(index)];
          if (currentObject === undefined) {
            return null;
          }
        } else {
          currentObject = Array.isArray(currentObject)
            ? currentObject.map((item) =>
                resolveSinglePath(item, pathSegments.slice(i + 1).join("."))
              )
            : [];
          break;
        }
      } else {
        currentObject = currentObject ? currentObject[segment] : undefined;
        if (currentObject === undefined) {
          return null;
        }
      }
    }

    return currentObject;
  }

  return reshape(response, shape);
}

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

export const reemplazarUrl = (url, numero) => url.replace(/v\d/, `v${numero}`);

export const formatoAEquivalenteHoras = (formato) => {
  const [dias = 0, horas] = formato.split(" ");
  return _.isNaN(parseInt(dias)) || _.isNaN(parseInt(horas))
    ? null
    : parseInt(dias) * 24 + parseInt(horas);
};

export const generarURL = (id, curso) => {
  const urlBase = window.location.origin.includes("herokuapp")
    ? "https://tecnoschool-services-dev.herokuapp.com/"
    : "https://services.tecnoschool.mx/";

  const queryObj = curso ? { curso: id } : { notas: id };
  return `${urlBase}?query=${btoa(JSON.stringify(queryObj))}`;
};

export const chunkSubstr = (str, size) =>
  _.chunk(str, size).map((chunk) => chunk.join(""));

const numberMapForGrades = {
  1: "PRIMERO",
  2: "SEGUNDO",
  3: "TERCERO",
  4: "CUARTO",
  5: "QUINTO",
  6: "SEXTO",
  7: "SÉPTIMO",
  8: "OCTAVO",
  9: "NOVENO",
  10: "DÉCIMO",
};

export const changePalabra = (numb) => numberMapForGrades[numb.toString()];

const numberMap = {
  1: "UNO",
  2: "DOS",
  3: "TRES",
  4: "CUATRO",
  5: "CINCO",
  6: "SEIS",
  7: "SIETE",
  8: "OCHO",
  9: "NUEVE",
  10: "DIEZ",
};

export const numb_to_letter = (numb) => {
  const parts = _.split(_.toString(numb), ".");

  const translatedParts = _.map(parts, (part) => _.get(numberMap, part, ""));

  return _.join(_.compact(translatedParts), " ");
};

// Función que retorna los campos modificados
export const getModifiedFields = (originalData, newData) =>
  _.omitBy(newData, (value, key) => _.isEqual(value, originalData[key]));

export const appendFormData = (formData, data, prefix = "") => {
  const value = Array.isArray(data)
    ? data.length && typeof data[0] !== "object"
      ? data.join(",")
      : JSON.stringify(data)
    : data;
  formData.append(prefix, value);
};

export const transformTelefonos = (telefonos) =>
  telefonos?.map((el) =>
    _.pick(el, el.isCreated ? ["tipo", "telefono"] : ["id", "tipo", "telefono"])
  ) || null;

export const fechaConLetras = (fechaStr) => {
  const [anno, mes, dia] = fechaStr.split("-");
  return `${parseInt(dia, 10)} de ${["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"][parseInt(mes) - 1]} de ${anno}`;
};

export function normalizarCadena(cadena) {
  return _.chain(cadena)
    .deburr() // Elimina tildes y otros diacríticos
    .toLower() // Convierte a minúsculas
    .replace(/\s+/g, "_") // Reemplaza los espacios por _
    .replace(/[^\w_]/g, "") // Elimina caracteres no alfanuméricos
    .value(); // Ejecuta la cadena de funciones
}

export function esImpar(numero) {
  // Convertir el número a entero
  const num = parseInt(numero, 10);

  // Verificar si la conversión fue exitosa y si el número es impar
  return !isNaN(num) && num % 2 !== 0;
}
export const paridadNew = (division) =>
  esImpar(division.division) && division.type === "Semestre" ? "NON" : "PAR";
