import {
  buildFiltersUrl,
  reemplazarUrl,
  reshape_response,
} from "helpers/functions";
import customFetchBase from "../../principal/customFetchBase";
import { createApi } from "@reduxjs/toolkit/query/react";
import { evaluacionesApi } from "./distribucionDeEvaluaciones.service";
import { appendFormData } from "helpers/functions";

export const asignaturasPorProfesorApi = createApi({
  reducerPath: "asignaturasPorProfesorApi",
  baseQuery: customFetchBase,
  tagTypes: ["AsignaturasPorProfesor", "Evaluaciones"],
  endpoints: (builder) => ({
    getAsignaturasPorProfesor: builder.query({
      query: ({ filter_params }) => ({
        url: buildFiltersUrl(
          "/v1/api/distribuciondeprofesorporasignatura/",
          filter_params
        ),
        method: "GET",
      }),
      providesTags: (result) =>
        Array.isArray(result)
          ? [
              ...result.map(({ id }) => ({
                type: "AsignaturasPorProfesor",
                id,
              })),
              { type: "AsignaturasPorProfesor", id: "LIST" },
            ]
          : [{ type: "AsignaturasPorProfesor", id: "LIST" }],
      transformResponse: (response, meta, args) => {
        if (Array.isArray(response)) {
          return response?.map((el) => reshape_response(el, args.shape));
        } else {
          const resultsTransformed = response?.results?.map((el) =>
            reshape_response(el, args.shape)
          );
          return {
            results: resultsTransformed,
            count: response.count,
            next: response.next,
            previous: response.previous,
          };
        }
      },
    }),
    getAsignaturasPorProfesorById: builder.query({
      query: (id) => ({
        url: `/v1/api/distribuciondeprofesorporasignatura/${id}/`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [
        { type: "AsignaturasPorProfesor", id },
      ],
    }),
    createAsignaturasPorProfesor: builder.mutation({
      query: (data) => {
        data.profesor = reemplazarUrl(data.profesor, 1);
        return {
          url: "/v1/api/distribuciondeprofesorporasignatura/",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [{ type: "AsignaturasPorProfesor", id: "LIST" }],
    }),
    deleteAsignaturasPorProfesor: builder.mutation({
      query: (id) => ({
        url: `/v1/api/distribuciondeprofesorporasignatura/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "AsignaturasPorProfesor", id: "LIST" }],
    }),
    editAsignaturasPorProfesor: builder.mutation({
      query: ({ id, ...rest }) => {
        if (rest.profesor !== undefined) {
          rest.profesor = reemplazarUrl(rest.profesor, 1);
        }
        const formData = new FormData();
        Object.entries(rest).map(([key, value]) => {
          formData.append(key, value);
        });
        return {
          url: `/v1/api/distribuciondeprofesorporasignatura/${id}/`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "AsignaturasPorProfesor", id },
              { type: "AsignaturasPorProfesor", id: "LIST" },
            ]
          : [{ type: "AsignaturasPorProfesor", id: "LIST" }],
    }),
    subirActaDist: builder.mutation({
      query: ({ id, ...rest }) => {
        const formData = new FormData();
        Object.entries(rest).forEach(([key, value]) => {
          appendFormData(formData, value, key);
        });
        return {
          url: `/v1/api/distribuciondeprofesorporasignatura/${id}/`,
          method: "PATCH",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: formData,
        };
      },
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "AsignaturasPorProfesor", id },
              { type: "AsignaturasPorProfesor", id: "LIST" },
            ]
          : [{ type: "AsignaturasPorProfesor", id: "LIST" }],
    }),
    cleanAsignaturasPorProfesor: builder.mutation({
      query: ({ id, ...rest }) => {
        return {
          url: `/v1/api/distribuciondeprofesorporasignatura/${id}/`,
          method: "PATCH",
          body: rest,
        };
      },
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "AsignaturasPorProfesor", id },
              { type: "AsignaturasPorProfesor", id: "LIST" },
            ]
          : [{ type: "AsignaturasPorProfesor", id: "LIST" }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          queryFulfilled.then(() => {
            dispatch(
              evaluacionesApi.util.invalidateTags([
                { type: "GruposPorEvaluaciones", id: "LIST" },
              ])
            );
          });
        } catch (error) {
          console.log(error);
        }
      },
    }),
  }),
});

export const {
  useGetAsignaturasPorProfesorQuery,
  useLazyGetAsignaturasPorProfesorQuery,
  useCreateAsignaturasPorProfesorMutation,
  useEditAsignaturasPorProfesorMutation,
  useSubirActaDistMutation,
  useCleanAsignaturasPorProfesorMutation,
  useDeleteAsignaturasPorProfesorMutation,
  useGetAsignaturasPorProfesorByIdQuery,
  useLazyGetAsignaturasPorProfesorByIdQuery,
} = asignaturasPorProfesorApi;
