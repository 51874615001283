import MDBox from "components/MDBox";

import React from "react";
import { CircularProgress } from "@mui/material";
import { useGetCicloByIdQuery, useGetExamenesQuery } from "services";
import TabsCardCalificaciones from "components/TabsCard/tabs_calificaciones";
import { useLocation } from "react-router-dom";
import { calificaciones } from "slices";
import { useSelector } from "react-redux";
import SelectCarrerasCalificaciones from "./components/selectCarreras";
import ExamenEvaluacion from "./components/examen-evaluacion/ExamenEvaluacion";

function DataTableWithTabs({ ciclo_id, cicle_name, activo, ind, ciclo_url }) {
  const { carreraSelect, loading: divisionLoading } =
    useSelector(calificaciones);
  const location = useLocation();
  const { data: carreras } = useGetCicloByIdQuery({
    id: ciclo_id,
    filter_params: {
      query: "{carreras{id,nombre,divisiones}}",
    },
  });

  const {
    data: examenes,
    isLoading,
    isFetching,
  } = useGetExamenesQuery({
    filter_params: {
      query: "{id,examen{url,id,nombre},desfase,estados,anterior,compartido}",
    },
    shape: {
      id: "examen.id",
      wrapperId: "id",
      nombre: "examen.nombre",
      url: "examen.url",
      desfase: "desfase",
      estado: "estados",
      anterior: "anterior",
      compartido: "compartido",
    },
  });

  if (divisionLoading) {
    return (
      <MDBox
        mt={5}
        width={"100%"}
        display={"flex"}
        py={5}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CircularProgress sx={{ marginBottom: "3rem" }} color="inherit" />
      </MDBox>
    );
  }

  return (
    <MDBox pb={3} key={location.pathname}>
      {!_.isEmpty(carreraSelect) ? (
        <SelectCarrerasCalificaciones carreras={carreras?.carreras} ind={ind} />
      ) : null}
      {isLoading ? (
        <MDBox
          width={"100%"}
          display={"flex"}
          py={5}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress sx={{ marginBottom: "3rem" }} color="inherit" />
        </MDBox>
      ) : (
        <TabsCardCalificaciones
          tabs={examenes?.map((el) => {
            return {
              id: String(el.nombre).toLowerCase(),
              label: el.nombre,
              element: (
                <ExamenEvaluacion
                  tableHeader={el.nombre}
                  ciclo={{
                    ind,
                    name: cicle_name,
                    id: ciclo_id,
                    activo,
                    url: ciclo_url,
                  }}
                  examen={{
                    id: el.wrapperId,
                    name: el.nombre,
                    desfase: el.desfase,
                    estadosEstudiantes: el.estado,
                    haveAnterior: !!el.anterior,
                    anteriorName: el.anterior?.examen.nombre,
                    compartido: el.compartido,
                    url: el.url,
                  }}
                />
              ),
            };
          })}
        />
      )}
    </MDBox>
  );
}

export default DataTableWithTabs;
