import { templatesApi } from "../view/modelos.service";

export const getTemplatesById = templatesApi.injectEndpoints({
  endpoints: (builder) => ({
    getTemplatesById: builder.query({
      query: (id) => ({
        url: `/v1/api/template/${id}/`,
        method: "GET",
      }),
      providesTags: (result, error, { id }) =>
        result
          ? [
              { type: "Templates", id },
              { type: "Templates", id: "LIST" },
            ]
          : [{ type: "Templates", id: "LIST" }],
    }),
  }),
});

export const { useGetTemplatesByIdQuery, useLazyGetTemplatesByIdQuery } =
  getTemplatesById;
