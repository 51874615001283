import * as React from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ModalBase from "components/ModalBase/modal_base";
import { useCloseModal } from "hooks/useCloseModal";
import MDButton from "components/MDButton";
import MDDropzone from "components/MDDropzone";

import pdf from "assets/images/tecnoschool/pdf.png";
import { CircularProgress } from "@mui/material";
import { useSubirActaMutation } from "services";

const style = {
  width: "40%",
  cursor: "pointer",
  borderRadius: "0.5rem",
  marginTop: "0.5rem",
  alignItems: "center",
};

const ModalUpload = ({ open, handleClose, id, examen }) => {
  const [subirActa, { isSuccess, isLoading, isError, error }] =
    useSubirActaMutation();

  const [file, setFile] = React.useState(null);
  const [errorUpload, setErrorUpload] = React.useState(null);
  const [fileUploaded, setFileUploaded] = React.useState(false);
  React.useEffect(() => {
    if (!open) {
      setFile(null);
      setFileUploaded(false);
      setErrorUpload(null);
    }
  }, [open]);
  React.useEffect(() => {
    if (file && file instanceof File) {
      if (file.name.split(".").pop() !== "pdf") {
        setErrorUpload("Solo se aceptan archivos PDF");
        setFileUploaded(false);
      } else {
        setFile(file);
        setErrorUpload(null);
        setFileUploaded(true);
      }
    }
  }, [file]);

  const dropzoneOptions = {
    maxFilesize: 1,
    acceptedFiles: "application/pdf",
    dictDefaultMessage: "Arrastra los archivos aquí para subirlos",
    init: function () {
      this.on("addedfile", (file) => {
        setFile(file);
      });
    },
  };

  useCloseModal(
    isLoading,
    isSuccess,
    isError,
    error,
    "Acta subida con éxito",
    handleClose
  );
  const submitForm = async () => {
    try {
      if (!file) {
        throw new Error("No se seleccionó ningún archivo");
      }
      await subirActa({
        distribucion_profesor_asignatura: id,
        acta: file,
        examen: examen,
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <ModalBase open={open} handleClose={handleClose}>
      <MDBox display="flex" flexDirection="column" alignItems="center">
        <MDTypography
          variant="h6"
          fontWeight="bold"
          flex
          justifyContent="center"
          textAlign="center"
        >
          Acta Firmada:
        </MDTypography>

        {!fileUploaded && (
          <MDDropzone
            options={dropzoneOptions}
            file={fileUploaded}
            id="actas"
          />
        )}
        {fileUploaded && (
          <MDBox
            component="img"
            src={pdf}
            variant="gradient"
            bgColor="dark"
            style={style}
          />
        )}

        {isLoading && (
          <CircularProgress
            sx={{ marginBottom: "2rem", marginTop: "2rem" }}
            color="inherit"
          />
        )}

        {errorUpload && (
          <MDTypography
            color="error"
            variant="h6"
            fontWeight="bold"
            display="flex"
            justifyContent="center"
            textAlign="center"
          >
            {errorUpload}
          </MDTypography>
        )}
      </MDBox>
      <MDBox
        mt={2}
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <MDButton
          type="submit"
          variant="gradient"
          color="dark"
          onClick={() => {
            setFile(null), setFileUploaded(false);
          }}
        >
          Quitar Archivo
        </MDButton>
        <MDButton
          disable={!fileUploaded.toString()}
          type="submit"
          variant="gradient"
          color="dark"
          onClick={submitForm}
        >
          Subir
        </MDButton>
      </MDBox>
    </ModalBase>
  );
};

ModalUpload.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ModalUpload;
