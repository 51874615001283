import MDTypography from "../../../../../../../components/MDTypography";

export default function ColoredMDTypography({ children, colorValidator }) {
  return (
    <MDTypography
      textGradient
      fontWeight="regular"
      variant="h6"
      color={colorValidator ? "success" : "error"}
    >
      {children ?? "---"}
    </MDTypography>
  );
}
