import * as React from "react";
import MDBox from "components/MDBox";
import { Link, useParams } from "react-router-dom";
import { Card, CircularProgress, Grid, Icon, Tooltip } from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import {
  useGetAsignaturasPorProfesorByIdQuery,
  useGetFechaEvaluacionQuery,
  useLazyGetTemplatesQuery,
  useNotasEstudiantesQuery,
} from "services";
import ComponentsPrivatization, {
  removeColumns,
} from "config/component_privatization";
import { style_icon } from "helpers/global_vars";
import { PROFESOR } from "roles";
import ModalUpload from "components/ModaUpload/modal_upload";
import useModalHandler from "../../../../../../hooks/useModalHandler";
import _ from "lodash";
import GrupedGrid from "../../../../../../components/GrupedGrid/GrupedGrid";
import { Encoder } from "../../../../../../helpers/encoder";
import { useSnackbar } from "notistack";
import ActaCalificaciones from "layouts/escuela/pdf/reactPdf/acta_calificaciones";
import { pdf } from "@react-pdf/renderer";
import { normalizarCadena } from "../../../../../../helpers/functions";
import ColoredMDTypography from "./internals/ColoredTypography";
import CalificarExamen from "../../../../../../components/ModalCalificar/CalificarExamen";

const ExamenShow = () => {
  const { enqueueSnackbar } = useSnackbar();
  //State del modal para calificar
  const {
    open: openCalificar,
    handleOpen: handleOpenCalificar,
    handleClose: handleCloseCalificar,
  } = useModalHandler();

  //State del modal para subir el acta
  const {
    open: openUpload,
    handleOpen: handleOpenUpload,
    handleClose: handleCloseUpload,
  } = useModalHandler();

  const [editEvaluaciones, setEditEvaluaciones] = React.useState(undefined);

  const [estXeval, setEstXeval] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);

  const { cicle_name, params } = useParams();

  const { profesor, grupo, examen } = Encoder.decodeBase64ToObject(
    decodeURIComponent(params)
  );

  const { data: fechas, isLoading: isLoadingF } = useGetFechaEvaluacionQuery({
    filter_params: {
      profesor: profesor,
      examen__nombre: examen.name,
      query: "{inicio,fin,abierto,accesible}",
    },
  });

  const { data: notas, isLoading: isLoadingN } = useNotasEstudiantesQuery(
    {
      id: profesor,
      filter_params: {
        estudiante__estudiante__estados: examen.estados,
        query:
          "{estudiante{id,url,distribucion_grupo{grupo{identificador}},estudiante{numero_de_control,user{first_name, apellido_paterno, apellido_materno}}}, notas{id,nota_final,aprobado,asistencias,porcentaje_de_asistencia,faltas,examen{nombre}}}",
      },
    },
    {
      refetchOnReconnect: true,
    }
  );

  const { data: distProf, isLoading: isLoadingDP } =
    useGetAsignaturasPorProfesorByIdQuery(profesor);

  const [getPlantillaPDF] = useLazyGetTemplatesQuery();

  const data = {
    columns: [
      {
        Header: "Nombre",
        accessor: "nombre",
        width: "60%",
        Cell: ({ row }) => (
          <ColoredMDTypography
            colorValidator={
              row?.original[normalizarCadena(examen.name)]?.aprobado
            }
          >
            {row?.original.nombre}
          </ColoredMDTypography>
        ),
      },
      {
        Header: !examen.anteriorName ? "Calificación" : examen.anteriorName,
        accessor: "nota_final",
        Cell: ({ row }) => (
          <ColoredMDTypography
            colorValidator={
              row?.original[normalizarCadena(examen.name)]?.aprobado
            }
          >
            {
              row?.original[
                normalizarCadena(
                  !examen.anteriorName ? examen.name : examen.anteriorName
                )
              ]?.nota_final
            }
          </ColoredMDTypography>
        ),
      },
      ...(!examen.anteriorName
        ? [
            {
              Header: "Asistencias",
              accessor: "asistencias",
              Cell: ({ row }) => (
                <ColoredMDTypography
                  colorValidator={
                    row?.original[normalizarCadena(examen.name)]?.aprobado
                  }
                >
                  {row?.original[normalizarCadena(examen.name)]?.asistencias ??
                    "---"}
                </ColoredMDTypography>
              ),
            },
            {
              Header: "Faltas",
              accessor: "faltas",
              Cell: ({ row }) => (
                <ColoredMDTypography
                  colorValidator={
                    row?.original[normalizarCadena(examen.name)]?.aprobado
                  }
                >
                  {row?.original[normalizarCadena(examen.name)]?.faltas ??
                    "---"}
                </ColoredMDTypography>
              ),
            },
            {
              Header: "% de asistencia",
              accessor: "porcentaje_de_asistencia",
              Cell: ({ row }) => (
                <ColoredMDTypography
                  colorValidator={
                    row?.original[normalizarCadena(examen.name)]?.aprobado
                  }
                >
                  {row?.original[normalizarCadena(examen.name)]
                    ?.porcentaje_de_asistencia
                    ? Math.trunc(
                        row?.original[normalizarCadena(examen.name)]
                          ?.porcentaje_de_asistencia
                      )
                    : "---"}
                </ColoredMDTypography>
              ),
            },
          ]
        : [
            {
              Header: examen.name,
              accessor: normalizarCadena(examen.name),
              Cell: ({ row }) => (
                <ColoredMDTypography
                  colorValidator={
                    row?.original[normalizarCadena(examen.name)]?.aprobado
                  }
                >
                  {row.original[normalizarCadena(examen.name)]?.nota_final
                    ? row.original[normalizarCadena(examen.name)]
                        ?.nota_final === 0
                      ? "NP"
                      : row.original[normalizarCadena(examen.name)]?.nota_final
                    : "---"}
                </ColoredMDTypography>
              ),
            },
          ]),
      {
        Header: "Acciones",
        accessor: "actions",
        width: "5%",
        Cell: ({ row }) => {
          const { original } = row;

          return (
            <MDBox display="flex">
              {_.isEmpty(original[normalizarCadena(examen.name)]) ? (
                <ComponentsPrivatization permitted={PROFESOR}>
                  <MDBox px={0.2}>
                    <Tooltip placement="top" title="Asignar nota al estudiante">
                      <Icon
                        style={style_icon}
                        onClick={() => handleClickCalificar(original)}
                        fontSize="small"
                      >
                        assignment_ind
                      </Icon>
                    </Tooltip>
                  </MDBox>
                </ComponentsPrivatization>
              ) : (
                <ComponentsPrivatization permitted={PROFESOR}>
                  <MDBox px={0.2}>
                    <Tooltip placement="top" title="Editar la calificación">
                      <Icon
                        style={style_icon}
                        onClick={() => handleClickCalificar(original)}
                        fontSize="small"
                      >
                        edit
                      </Icon>
                    </Tooltip>
                  </MDBox>
                </ComponentsPrivatization>
              )}
            </MDBox>
          );
        },
      },
    ],
    rows: notas ?? [],
  };

  const handleClickCalificar = React.useCallback(
    async (elem) => {
      try {
        if (!_.isEmpty(elem[normalizarCadena(examen.name)]))
          setEditEvaluaciones(elem);
        else setEditEvaluaciones(undefined);
        setEstXeval(
          notas?.filter((e) => e.url_dist_estu === elem.url_dist_estu)[0]
        );
        handleOpenCalificar();
      } catch (err) {
        console.log(err);
      }
    },
    [handleOpenCalificar]
  );

  const handleClickActa = async () => {
    setLoading(true);
    try {
      //el objeto info se le pasa en la parte de la tabla para generar el pdf, ahora hay q hacerlo manualmente xq la peticion se hace de otra forma, entonces para no joder mas codigo se hace aki a mano
      const info = {
        semestre: distProf.distribucion_grupo.ciclo_interno.numero_de_ciclo,
        carrera: distProf.distribucion_grupo.ciclo_interno.carrera.nombre,
        profesor_name: `${distProf.profesor.user.apellido_paterno} ${distProf.profesor.user.apellido_materno} ${distProf.profesor.user.first_name}`,
        asignatura: distProf.asignatura.nombre,
        asignatura_clave: distProf.asignatura.clave,
        grupo_ident: distProf.distribucion_grupo.grupo.identificador,
      };
      const plantilla = await getPlantillaPDF({
        filter_params: {
          tipo: "calificaciones",
        },
      });
      let obj = pdf(
        <ActaCalificaciones
          info={info}
          cicle_name={cicle_name}
          tipo_examen={examen.name}
          plantilla={plantilla?.data[0].imagenes}
          notas={notas}
        />
      ).toBlob();
      obj.then(function (blob) {
        let url = URL.createObjectURL(blob);
        window.open(url, "_blank");
      });
    } catch (error) {
      enqueueSnackbar(`Hubo algún error generando el acta`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {distProf ? (
        <>
          <CalificarExamen
            open={openCalificar}
            handleClose={handleCloseCalificar}
            distribucion_profesor={distProf?.url}
            distribucion_estudiante={estXeval}
            estudiantes={notas?.filter((el) =>
              !examen.anteriorName
                ? !el[normalizarCadena(examen.name)]?.aprobado
                : !el[normalizarCadena(examen.name)]?.aprobado &&
                  !el[normalizarCadena(examen.anteriorName)]?.aprobado
            )}
            edit={editEvaluaciones}
          />
          <ModalUpload
            open={openUpload}
            handleClose={handleCloseUpload}
            id={distProf?.id}
            examen={examen.url}
          />
        </>
      ) : null}

      <MDBox pt={6} pb={3}>
        <Card>
          <Grid container>
            <Grid item xs={12} sx={{ mx: "auto" }}>
              <MDBox>
                <Grid
                  container
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems={"baseline"}
                  m={2}
                  px={5}
                  py={3}
                >
                  <GrupedGrid
                    data={[
                      {
                        label: "CARRERA",
                        value:
                          distProf?.distribucion_grupo.ciclo_interno.carrera
                            .nombre ?? "",
                      },
                      { label: "GRUPO", value: grupo ?? "" },
                      {
                        label: "ASIGNATURA",
                        value: distProf?.asignatura.nombre ?? "",
                      },
                      {
                        label: "PROFESOR",
                        value: distProf?.profesor.user
                          ? `${distProf?.profesor.user.apellido_paterno} ${distProf?.profesor.user.apellido_materno} ${distProf?.profesor.user.first_name}`
                          : "",
                      },
                    ]}
                  />

                  <Grid item gap={2} display={"flex"} xs={12} sm={2}>
                    <ComponentsPrivatization permitted={PROFESOR}>
                      {notas?.filter(
                        (el) => el[normalizarCadena(examen.name)]?.aprobado
                      ).length === notas?.length &&
                      !_.isEmpty(notas) &&
                      !distProf?.acta &&
                      fechas &&
                      !_.isEmpty(fechas) &&
                      fechas[0]?.accesible ? (
                        <MDBox display="flex" gap={1}>
                          <Tooltip
                            placement="top"
                            title="Subir Acta Firmada en formato pdf, luego de subir el acta no podrá editar más las notas de los estudiantes"
                          >
                            <MDButton
                              variant="gradient"
                              color="dark"
                              size="medium"
                              onClick={() => handleOpenUpload()}
                              circular
                              iconOnly
                            >
                              <Icon>upload</Icon>
                            </MDButton>
                          </Tooltip>
                          <Tooltip
                            placement="top"
                            title="Imprimir Acta, podrá ver el pdf de las calificaciones correspondientes al grupo"
                          >
                            <MDButton
                              variant="gradient"
                              color="dark"
                              size="medium"
                              onClick={() => handleClickActa()}
                              circular
                              iconOnly
                              loading={loading}
                            >
                              <Icon>print</Icon>
                            </MDButton>
                          </Tooltip>
                        </MDBox>
                      ) : null}
                      {distProf?.acta ? (
                        <MDButton
                          variant="gradient"
                          color="dark"
                          size="medium"
                          onClick={() => window.open(distProf.acta, "_blank")}
                          circular
                          iconOnly
                        >
                          <Tooltip
                            placement="top"
                            title="Ver Acta firmada, mostrará el acta ya subida por el profesor al servidor"
                          >
                            <Icon>picture_as_pdf</Icon>
                          </Tooltip>
                        </MDButton>
                      ) : null}
                    </ComponentsPrivatization>
                    <Link to={`/ciclos/${cicle_name}/calificaciones`}>
                      <MDButton variant="gradient" color="info" size="medium">
                        Atras
                      </MDButton>
                    </Link>
                  </Grid>
                </Grid>

                <MDBox mt={3} mb={1} ml={0.5}>
                  <MDBox
                    display="flex"
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    {isLoadingN || isLoadingF ? (
                      <CircularProgress
                        sx={{ marginBottom: "3rem" }}
                        color="inherit"
                      />
                    ) : (
                      <Grid item xs={12} sx={{ mx: "auto" }}>
                        <DataTable
                          indice
                          canSearch
                          table={removeColumns(
                            removeColumns(
                              removeColumns(
                                data,
                                () =>
                                  _.isEmpty(fechas) || !fechas[0]?.accesible,
                                ["actions"]
                              ),
                              () => distProf?.acta,
                              ["actions"]
                            ),
                            () =>
                              !JSON.parse(
                                sessionStorage.getItem("user")
                              ).groups.some((role) => role.name === PROFESOR),
                            ["actions"]
                          )}
                        />
                      </Grid>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
    </>
  );
};

export default ExamenShow;
