import { cicloApi } from "../view/ciclo.service";
import {
  buildFiltersUrl,
  reshape_response,
} from "../../../../helpers/functions";

export const getCiclos = cicloApi.injectEndpoints({
  endpoints: (builder) => ({
    getCiclos: builder.query({
      query: (args) => {
        let url = "/v1/api/ciclo/";

        return {
          url: !args ? url : buildFiltersUrl(url, args.filter_params),
          method: "GET",
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Ciclo",
                id,
              })),
              { type: "Ciclo", id: "LIST" },
            ]
          : [{ type: "Ciclo", id: "LIST" }],
      transformResponse: (response, meta, args) => {
        if (!args?.shape || !args) return response;

        if (Array.isArray(response)) {
          return response?.map((el) => reshape_response(el, args.shape));
        } else {
          const resultsTransformed = response?.results?.map((el) =>
            reshape_response(el, args.shape),
          );
          return {
            results: resultsTransformed,
            count: response.count,
            next: response.next,
            previous: response.previous,
          };
        }
      },
    }),
  }),
});

export const { useGetCiclosQuery, useLazyGetCiclosQuery } = getCiclos;
