export const style_icon = {
  cursor: "pointer",
};

export const ESTADOS_ESTUDIANTES = [
  "Activo",
  "Baja Temporal",
  "Baja Definitiva",
  "Graduado",
];

export const TIPOS_SANGRES = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];
