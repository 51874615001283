export const LOGIN = "/";
export const RESET_PASSWORD = "/reset";
export const RESET_PASSWORD_CONFIRM = "/reset/confirm";

export const HOME_URL = "/home";

export const ADQ_LIST_URL = "/catalogos/adquisicion";
export const UNIDADES_URL = "/catalogos/unidades-medida";
export const ESTADO_ACTIVOS_URL = "/catalogos/estado-activo";
export const TIPOS_ACTIVOS_URL = "/catalogos/tipos";
export const AREA_LIST_URL = "/institucion/lista-areas";

export const ACTIVOS_LIST_URL = "/activos/tangibles/almacen";
export const ACTIVOS_RESGUARDOS_LIST_URL = "/activos/tangibles/resguardo";
export const ACTIVOS_BAJAS_LIST_URL = "/activos/tangibles/bajas";

export const ACTIVO_NEW_URL = "/activos/tangibles/almacen/nuevo";

export const ACTIVO_CLONE_URL = (id) =>
  `/activos/tangibles/almacen/clone/${id ?? ":id"}`;

export const ACTIVO_EDIT_URL = (id) =>
  `/activos/tangibles/almacen/editar/${id ?? ":id"}`;

export const ACTIVO_RESGUARDAR_URL = "/activos/tangibles/almacen/resguardar";

export const ACTIVOS_CONSUMIBLES_URL = "/activos/consumibles/almacen";
export const ACTIVOS_CONSUMIBLES_RESGUARDOS_LIST_URL =
  "/activos/consumibles/resguardo";

export const CONSUMIBLE_CLONE_URL = (id) =>
  `/activos/consumibles/almacen/clone/${id ?? ":id"}`;

export const CONSUMIBLES_NEW_URL = "/activos/consumibles/nuevo";

export const CONSUMIBLES_EDIT_URL = (id) =>
  `/activos/consumibles/editar/${id ?? ":id"}`;

export const PERSONAL_LIST_URL = "/plantilla/personal";

export const USUARIOS_URL = "/administracion/usuarios";
export const ENTIDADES_URL = "/administracion/entidades";

export const MOVIMIENTOS_ASIGNACION_URL = "/movimientos/asignacion";
export const MOVIMIENTOS_DEVOLUCION_URL = "/movimientos/devolucion";

export const PERSONAL_NEW_URL = "/plantilla/personal/nuevo";

export const PERSONAL_EDIT_URL = (id) =>
  `/plantilla/personal/editar/${id ?? ":id"}`;

export const AREA_NEW_URL = "/institucion/lista-areas/nuevo";

export const AREA_EDIT_URL = () => "/institucion/lista-areas/editar";

export const ADQ_NEW_URL = "/catalogos/catalogo-adquisicion/nuevo";

export const ADQ_EDIT_URL = (id) =>
  `/catalogos/catalogo-adquisicion/editar/${id ?? ":id"}`;

export const ESTADO_NEW_URL = "/catalogos/estado-activo/nuevo";

export const ESTADO_EDIT_URL = (id) =>
  `/catalogos/estado-activo/editar/${id ?? ":id"}`;

export const TIPO_NEW_URL = "/catalogos/tipos/nuevo";

export const TIPO_EDIT_URL = (id) => `/catalogos/tipos/editar/${id ?? ":id"}`;

export const USER_NEW_URL = "/administracion/usuarios/nuevo";
export const USER_EDIT_URL = "/administracion/usuarios/editar";

export const ENTIDAD_NEW_URL = "/administracion/entidades/nuevo";
export const ENTIDAD_EDIT_URL = "/administracion/entidades/editar";

export const UNIDADES_NEW_URL = "/catalogos/unidades-medida/nuevo";

export const UNIDADES_EDIT_URL = (id) =>
  `/catalogos/unidades-medida/editar/${id ?? ":id"}`;

export const ESTUDIANTES_LIST_URL = "/estudiantes/todos";
export const ESTUDIANTES_NEW_URL = "/estudiantes/nuevo";

export const ESTUDIANTES_EDIT_URL = (id) =>
  `/estudiantes/editar/${id ?? ":id"}`;

export const ESTUDIANTES_DETALLES_QR_URL = "/estudiantes/detalles/qr";
export const TRABAJADORES_DETALLES_QR_URL = "/trabajadores/detalles/qr";

export const REGISTROS_URL = "/plantilla/registros";
export const REGISTRO_NEW_URL = "/plantilla/registros/nuevo";

export const REGISTRO_EDIT_URL = (id) =>
  `/plantilla/profesores/editar/${id ?? ":id"}`;

/** Ciclos y rutas anidadas */
export const CICLOS_EDIT_URL = (id) =>
  `/ciclos/ciclos-list/editar/${id ?? ":id"}`;

export const GRUPOS_LIST_URL = "/ciclos";
export const GRUPOS_NEW_URL = "/ciclos/:cicle_name/grupos/nuevo";
export const GRUPOS_EDIT_URL = (cicle_name, id) =>
  `/ciclos/${cicle_name ?? ":cicle_name"}/grupos/editar/${id ?? ":id"}`;

export const GRUPOS_PROMOVER_URL = (cicle_name) =>
  `/ciclos/${cicle_name ?? ":cicle_name"}/grupos/promover`;

export const GRUPOS_SHOW_URL = (cicle_name, group_id, ciclo_id) =>
  `ciclos/${cicle_name ?? ":cicle_name"}/grupos/grupo/${
    group_id ?? ":id"
  }/ciclo/${ciclo_id ?? ":ciclo_id"}`;

export const EXAMEN_CALIFICACION_SHOW_URL = (cicle_name, params) =>
  `/ciclos/${cicle_name ?? ":cicle_name"}/calificaciones/${params ?? ":params"}`;

export const CALIFICACIONES_SHOW_URL =
  "/ciclos/:cicle_name/calificaciones/ordinaria/grupo/:grupo/profesor/:profesor/semestre/:semestre";
export const REGULARIZACION_SHOW_URL =
  "/ciclos/:cicle_name/calificaciones/regularizacion/grupo/:grupo/profesor/:profesor/semestre/:semestre";
export const REGULARIZACION2_SHOW_URL =
  "/ciclos/:cicle_name/calificaciones/regularizacion2/grupo/:grupo/profesor/:profesor/semestre/:semestre";

export const ASIGNATURAS_LIST_URL = "/institucion/asignaturas";
export const ASIGNATURAS_EDIT_URL = (id) =>
  `/institucion/asignaturas/editar/${id ?? ":id"}`;

export const CARRERAS_LIST_URL = "/institucion/carreras";
export const CARRERAS_NEW_URL = "/institucion/carreras/nuevo";
export const CARRERAS_EDIT_URL = (id) =>
  `/institucion/carreras/editar/${id ?? ":id"}`;

export const EXAMEN_LIST_URL = "/configuración/exámenes";
export const EXAMEN_ADD_URL = "/configuración/exámenes/nuevo";
export const EXAMEN_EDIT_URL = (id) =>
  `/configuración/exámenes/editar/${!id ? ":id" : id}`;

export const MODELOS_LIST_URL = "/configuración/modelos";
export const MODELOS_EDIT_URL = (id, tipo) =>
  `/configuración/modelos/editar/${!id ? ":id" : id}/tipo/${!tipo ? ":tipo" : tipo}`;

export const DISTRIBUCIONES_URL = "/ciclos/:cicle_name/grupos/distribuciones";

export const CURSOS_LIST_URL = "/extracurricular/cursos";
export const CURSOS_SHOW_URL = (id) =>
  `/extracurricular/cursos/${!id ? ":id" : id}`;
export const CURSOS_NEW_URL = "/cursos/nuevo";
export const CURSOS_EDIT_URL = (id) => `/cursos/editar/${!id ? ":id" : id}`;
export const CURSOS_DETALLES_QR_URL = "/cursos/detalles/qr";

export const FIRMANTES_LIST_URL = "/personal/firmantes";
export const FIRMANTES_NEW_URL = "/personal/firmantes/nuevo";
export const FIRMANTES_EDIT_URL = (id) =>
  `/personal/firmantes/editar/${id ?? ":id"}`;

export const CONSTANCIAS_ESTUDIANTES_URL = "/extracurricular/constancias";
export const BOLETAS_ESTUDIANTES_URL = "/documentos/boletas";
export const BOLETAS_DETALLES_QR_URL = "/boletas/detalles/qr";

export const CERTIFICADO_ELECTRONICO = "/certificado-electrónico";
