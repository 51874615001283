import { CircularProgress, Grid, MenuItem } from "@mui/material";
import FormField from "components/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import ModalBase from "components/ModalBase/modal_base";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import {
  useGetExamenesQuery,
  useLazyGetCarrerasPorGrupoQuery,
  useLazyGetTaskQuery,
  useMakeHistorialMutation,
} from "services";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  examen: Yup.string().required("Examen es obligatorio"),
  carrera: Yup.string().test(
    "carrera-required",
    "Debe ingresar una Carrera o un Semestre o los 2 sino hay Grupo",
    function (value) {
      const { grupo, semestre } = this.parent;
      if (
        (!grupo || grupo.trim() === "") &&
        (!semestre || semestre.trim() === "")
      ) {
        return !!value && value.trim() !== "";
      }
      return true;
    }
  ),
  semestre: Yup.string().test(
    "semestre-required",
    "Debe ingresar un Semestre o una Carrera o los 2 sino hay Grupo",
    function (value) {
      const { grupo, carrera } = this.parent;
      if (
        (!grupo || grupo.trim() === "") &&
        (!carrera || carrera.trim() === "")
      ) {
        return !!value && value.trim() !== "";
      }
      return true;
    }
  ),
  grupo: Yup.string(),
});

const HistorialComponent = ({ open, handleClose, ciclo_id }) => {
  const [historial] = useMakeHistorialMutation();
  const [triggerTarea] = useLazyGetTaskQuery();
  const [cargando, setCargando] = useState(false);
  const [datos, setDatos] = useState({
    carreras: [],
    semestres: [],
    grupos: [],
  });

  const [warning, setWarning] = useState("");
  const [mensaje, setMensaje] = useState("");

  const { data: examenes } = useGetExamenesQuery(
    {
      filter_params: {
        query: "{id,examen{id,url,nombre}}",
      },
      shape: {
        id: "examen.id",
        url: "examen.url",
        nombre: "examen.nombre",
      },
    },
    {
      refetch_on_reconnect: true,
    }
  );

  useEffect(() => {
    if (open)
      distribuciones({
        filter_params: {
          ordering:
            "ciclo_interno__numero_de_ciclo,grupo__identificador,ciclo_interno__carrera__nombre",
          ciclo: ciclo_id,
          query:
            "{url,grupo{identificador},ciclo_interno{numero_de_ciclo,carrera{nombre,plan_de_estudio}}}",
        },
        shape: {
          url: "url",
          identificador: "grupo.identificador",
          carrera: "ciclo_interno.carrera.nombre",
          semestre: "ciclo_interno.numero_de_ciclo",
          plan_de_estudio: "ciclo_interno.carrera.plan_de_estudio",
        },
      })
        .unwrap()
        .then((res) => {
          setDatos({
            carreras: extraerCarreraConPlanEstudio(res),
            semestres: extraerPropiedadUnica(res, "semestre"),
            grupos: res.map((obj) => {
              return { identificador: obj.identificador, url: obj.url };
            }),
          });
        });
  }, [open]);

  const [distribuciones, { data: resultados }] =
    useLazyGetCarrerasPorGrupoQuery();

  const llamarHistorial = async (body) => {
    try {
      const response = await historial({
        filter_params: {
          query: "{id}",
        },
        body: body,
      }).unwrap();

      return response;
    } catch (error) {
      console.error("Error al llamar a la mutación:", error);
    }
  };

  const submitForm = async (values) => {
    setCargando(true);
    if (values.grupo) {
      //si hay valor en grupo no se tiene en cuenta la carrera y el semestre
      if (values.grupo === "todos-grupo") {
        //el caso de q se seleccione todos los grupos
        const response = await llamarHistorial({
          carrera_grupos: datos.grupos.map((e) => e.url),
          examen: values.examen,
        });
        peticionTarea(response.id, "Todos los grupos");
      } else {
        // en este caso solo hay un grupo seleccionado
        const grupoName = values.grupo.split("~"); // se hace split para separar el url del identificador del grupo para usarlo en distintos lugares
        const response = await llamarHistorial({
          carrera_grupos: [grupoName[0]],
          examen: values.examen,
        });
        peticionTarea(response.id, grupoName[1]);
      }
    }
    if (!values.grupo && !values.carrera && values.semestre) {
      //si no hay valor en grupo ni en carrera solo se tiene en cuenta el numero del semestre
      //se filtran los valores de las distribuciones con respecto al numero del semestre y luego se devuelve solo un arreglo con las url
      const response = await llamarHistorial({
        carrera_grupos: resultados
          .filter((e) => e.semestre === values.semestre)
          .map((e) => e.url),
        examen: values.examen,
      });
      peticionTarea(response.id, `Semestre ${values.semestre}`);
    }
    if (!values.grupo && !values.semestre && values.carrera) {
      //si no hay valor en grupo ni en semestre solo se tiene en cuenta la carrera
      const carrera_nombre = values.carrera.split("-");
      //filtramos de todas las ditribuciones de grupos, solo las d la carrera seleccionada luego mapeamos y sacamos el url
      const filtered_carreras = resultados
        .filter((e) => e.carrera === carrera_nombre[0])
        .map((e) => e.url);
      const response = await llamarHistorial({
        carrera_grupos: filtered_carreras,
        examen: values.examen,
      });
      peticionTarea(response.id, values.carrera);
    }
    if (!values.grupo && values.semestre && values.carrera) {
      //si hay valores en carrera y semestre, el filtro tiene q tener en cuanta las 2 propiedades
      const carrera_nombre = values.carrera.split("-");
      const response = await llamarHistorial({
        carrera_grupos: resultados
          .filter(
            (e) =>
              e.carrera === carrera_nombre[0] && e.semestre === values.semestre
          )
          .map((e) => e.url),
        examen: values.examen,
      });
      peticionTarea(
        response.id,
        `${values.carrera}, Semestre: ${values.semestre}`
      );
    }
  };

  const peticionTarea = async (id, nombre) => {
    triggerTarea(id)
      .unwrap()
      .then((res) => {
        if (res.estado === "COMPLETED") {
          descargarCSV(res.resultado, nombre);
          handleClose();
        } else if (res.estado === "ERROR") {
          setMensaje(
            "Hubo un error generando el historial contacte con el proveedor"
          );
          setTimeout(() => {
            handleClose();
          }, [2000]);
        } else {
          setTimeout(() => {
            peticionTarea(id, nombre);
          }, [10000]);
        }
      });
  };

  const descargarCSV = (resultado, nombre) => {
    setCargando(false);
    const csvContent = "\uFEFF" + resultado;
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `Historial ${nombre}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSubmit = async (values, actions) => {
    submitForm(values, actions);
  };

  function extraerPropiedadUnica(arr, propiedad) {
    const word = arr.map((obj) => obj[propiedad]);
    return [...new Set(word)];
  }
  function extraerCarreraConPlanEstudio(arr) {
    const carrera = arr.map((obj) => `${obj.carrera}-${obj.plan_de_estudio}`);
    return [...new Set(carrera)];
  }

  return (
    <ModalBase open={open} handleClose={handleClose}>
      <MDBox>
        <MDBox>
          {(datos.carreras.length &&
            datos.semestres.length &&
            datos.grupos.length) !== 0 && (
            <Formik
              initialValues={{
                examen: "",
                semestre: "",
                carrera: "",
                grupo: "",
              }}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ values, handleChange, errors, touched }) => {
                useEffect(() => {
                  const { carrera, semestre, grupo } = values;
                  if (grupo && (carrera || semestre)) {
                    setWarning(
                      "Carrera y Semestre no se tendrán en cuenta si Grupo está presente. Si desea tener en cuenta estos valores, quite el valor de Grupo."
                    );
                  } else {
                    setWarning("");
                  }
                }, [values]);
                return (
                  <Form>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12}>
                        <FormField
                          select
                          id="examen"
                          value={values.examen}
                          label={"examen"}
                          name={"examen"}
                          error={errors.examen && touched.examen}
                          onChange={handleChange}
                          fullWidth
                        >
                          <MenuItem key={"no-e"} value={""}>
                            No Seleccionada
                          </MenuItem>
                          {examenes?.map((el) => (
                            <MenuItem key={el.id} value={el.url}>
                              {el.nombre}
                            </MenuItem>
                          ))}
                        </FormField>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormField
                          select
                          id="carrera"
                          value={values.carrera}
                          label={"Carrera"}
                          name={"carrera"}
                          error={errors.carrera && touched.carrera}
                          onChange={handleChange}
                          fullWidth
                        >
                          <MenuItem key={"no-c"} value={""}>
                            No Seleccionada
                          </MenuItem>
                          {datos.carreras.map((el) => (
                            <MenuItem key={el} value={el}>
                              {el}
                            </MenuItem>
                          ))}
                        </FormField>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormField
                          select
                          id="semestre"
                          value={values.semestre}
                          label={"Número del semestre"}
                          name={"semestre"}
                          error={errors.semestre && touched.semestre}
                          onChange={handleChange}
                          fullWidth
                        >
                          <MenuItem key={"no-s"} value={""}>
                            No Seleccionado
                          </MenuItem>
                          {datos.semestres.map((el) => (
                            <MenuItem key={el} value={el}>
                              {el}
                            </MenuItem>
                          ))}
                        </FormField>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormField
                          select
                          id="grupo"
                          value={values.grupo}
                          label={"Grupo"}
                          name={"grupo"}
                          error={errors.grupo && touched.grupo}
                          onChange={handleChange}
                          fullWidth
                        >
                          <MenuItem key={"no-g"} value={""}>
                            No Seleccionado
                          </MenuItem>
                          {datos.grupos.map((el) => (
                            <MenuItem
                              key={el.identificador}
                              value={`${el.url}~${el.identificador}`}
                            >
                              {el.identificador}
                            </MenuItem>
                          ))}
                          <MenuItem key={"todos-g"} value={"todos-grupo"}>
                            Todos
                          </MenuItem>
                        </FormField>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        {warning && (
                          <MDTypography
                            variant="caption"
                            color="warning"
                            component="div"
                            fontWeight="regular"
                          >
                            {warning}
                          </MDTypography>
                        )}
                      </Grid>
                    </Grid>
                    <MDBox
                      mt={3}
                      display="flex"
                      flexDirection="row"
                      width="100%"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MDButton
                        variant={"gradient"}
                        color={"secondary"}
                        size="medium"
                        type="submit"
                      >
                        Aceptar
                      </MDButton>
                      {cargando && (
                        <CircularProgress
                          sx={{ marginLeft: "4px" }}
                          size={20}
                          color="inherit"
                        />
                      )}
                      <MDButton
                        variant="gradient"
                        color="error"
                        size="medium"
                        onClick={handleClose}
                      >
                        Cancelar
                      </MDButton>
                    </MDBox>
                    <Grid container mt={3}>
                      <Grid item xs={12} sm={12}>
                        {cargando && (
                          <MDTypography
                            variant="caption"
                            color="error"
                            component="div"
                            fontWeight="regular"
                            display="flex"
                            justifyContent="center"
                          >
                            {mensaje}
                          </MDTypography>
                        )}
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          )}
        </MDBox>
      </MDBox>
    </ModalBase>
  );
};

export default HistorialComponent;
