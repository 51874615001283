/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// formik components
import { Form, Formik } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Tooltip } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// NewUser layout schemas for form and form feilds
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import { useNavigate, useParams } from "react-router-dom";
import * as React from "react";
import { useEffect } from "react";
import MDTypography from "components/MDTypography";
import { GRUPOS_LIST_URL } from "config/bases_url";
import { useRedirectForm } from "hooks/useRedirectForm";
import { useGetCiclosQuery, usePromoverGrupoEscolarMutation } from "services";
import Promover from "./components/promover_info";
import FormErrorHandler from "../../../../handlers/FormErrorHandler/form-error.handler";
import { ErrorBundary } from "../../../../config/ErrorBundary/error-bundary";

function PromoverGrupoEscolar() {
  const currentValidation = validations[0];

  const { cicle_name } = useParams();

  const { data: ciclos } = useGetCiclosQuery(undefined, {
    refetchOnReconnect: true,
  });

  const [
    promoverGrupo,
    {
      isSuccess: isSuccessCC,
      isLoading: isLoadingCC,
      isError: isErrorCC,
      error: errorCC,
    },
  ] = usePromoverGrupoEscolarMutation();

  const { formId, formField } = form;

  const navigate = useNavigate();
  useRedirectForm(
    isLoadingCC,
    isSuccessCC,
    isErrorCC,
    errorCC,
    "Grupo Escolar promovido con éxito",
    `${GRUPOS_LIST_URL}/${cicle_name}/grupos`,
  );

  const submitForm = async (values, actions) => {
    try {
      await promoverGrupo({
        id: values.grupo_id_clonar,
        identificador: values.identificador,
      });

      if (isSuccessCC) {
        navigate(`${GRUPOS_LIST_URL}/${cicle_name}/grupos`);
        actions.setSubmitting(false);
        actions.resetForm();
      }
    } catch (error) {
      console.error(error);
      actions.setSubmitting(true);
    }
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
  };

  return (
    <MDBox mt={5} mb={20}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={8}>
          <MDBox mt={6} mb={8} textAlign="center">
            <MDBox mb={1}>
              <MDTypography variant="h3" fontWeight="bold">
                Grupo Escolar
              </MDTypography>
            </MDBox>
            <MDTypography variant="h5" fontWeight="regular" color="secondary">
              Verifique la información mostrada para promover un grupo a otro
              semestre
            </MDTypography>
          </MDBox>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={currentValidation}
          >
            {({ values, errors, touched, setFieldValue }) => {
              useEffect(() => {
                if (values.clonar === "Actual" && ciclos) {
                  setFieldValue(
                    formField.ciclo_id.name,
                    ciclos[ciclos?.length - 1].id,
                  );
                } else if (values.clonar === "Anterior" && ciclos) {
                  setFieldValue(
                    formField.ciclo_id.name,
                    ciclos[ciclos?.length - 2].id,
                  );
                }
              }, [ciclos, values.clonar]);

              return (
                <ErrorBundary renderOnError={() => <FormErrorHandler />}>
                  <Form id={formId} autoComplete="off">
                    <MDBox display="flex" justifyContent="space-evenly" mb={3}>
                      <Tooltip
                        placement="top"
                        title="Si selecciona esta opción se mostrarán los grupos del ciclo anterior"
                      >
                        <div>
                          <MDButton
                            value="Anterior"
                            variant={
                              values.clonar === "Anterior"
                                ? "outlined"
                                : "gradient"
                            }
                            color={
                              values.clonar === "Anterior"
                                ? "success"
                                : "secondary"
                            }
                            size="medium"
                            onClick={(e) => {
                              if (values.clonar !== e.currentTarget.value) {
                                setFieldValue(
                                  formField.clonar.name,
                                  e.currentTarget.value,
                                );
                              }
                            }}
                            disabled={ciclos?.length === 1}
                          >
                            Anterior
                          </MDButton>
                        </div>
                      </Tooltip>
                      <Tooltip
                        placement="top"
                        title="Si selecciona esta opción se mostrarán los grupos del ciclo actual"
                      >
                        <div>
                          <MDButton
                            value="Actual"
                            variant={
                              values.clonar === "Actual"
                                ? "outlined"
                                : "gradient"
                            }
                            color={
                              values.clonar === "Actual"
                                ? "success"
                                : "secondary"
                            }
                            size="medium"
                            onClick={(e) => {
                              if (values.clonar !== e.currentTarget.value) {
                                setFieldValue(
                                  formField.clonar.name,
                                  e.currentTarget.value,
                                );
                              }
                            }}
                          >
                            Actual
                          </MDButton>
                        </div>
                      </Tooltip>
                    </MDBox>

                    <Card sx={{ height: "100%" }}>
                      <MDBox p={3}>
                        <MDBox>
                          {values.ciclo_id !== null && ciclos?.length > 0 && (
                            <Promover
                              formData={{
                                values,
                                touched,
                                formField,
                                errors,
                                setFieldValue,
                              }}
                            />
                          )}
                          <MDBox
                            mt={2}
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                          >
                            <MDButton
                              onClick={(e) => {
                                navigate(
                                  `${GRUPOS_LIST_URL}/${cicle_name}/grupos`,
                                );
                              }}
                              variant="gradient"
                              color="primary"
                            >
                              cancelar
                            </MDButton>
                            <MDButton
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              aceptar
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Form>
                </ErrorBundary>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default PromoverGrupoEscolar;
