// Images
import logoderecha from "assets/images/tecnoschool/cren_benito/logoderechaMenos.png";
import crenLogo from "assets/images/tecnoschool/cren_benito/cren.png";
import sierraLogo from "assets/images/tecnoschool/sierra/sierra.png";
import {
  Document,
  Page,
  Image,
  Text,
  Font,
  StyleSheet,
  View,
  Svg,
  Line,
} from "@react-pdf/renderer";
import {
  changePalabra,
  numb_to_letter,
  paridadNew,
} from "../../../../helpers/functions";
import ArialMT_Bold from "../../../../assets/fonts/arial-mt-cufonfonts/ARIALBOLDMT.OTF";
import ArialMT from "../../../../assets/fonts/arial-mt-cufonfonts/arialmt.ttf";

Font.register({
  family: "ArialMT",
  fonts: [
    { src: ArialMT, fontWeight: "light" },
    { src: ArialMT_Bold, fontWeight: "bold" },
  ],
});

const styles = StyleSheet.create({
  textBold: {
    fontFamily: "ArialMT",
    fontWeight: "bold",
    fontSize: "10pt",
    textTransform: "uppercase",
  },
  textInfoTitle: {
    fontFamily: "ArialMT",
    fontSize: "7pt",
    textTransform: "uppercase",
    lineHeight: "1.8",
    fontWeight: "bold",
  },
  textInfo: {
    fontFamily: "ArialMT",
    fontSize: "7pt",
    textTransform: "uppercase",
    marginLeft: "5px",
    lineHeight: "1.8",
  },
  texWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "4px",
  },
  infoIntermedia: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    textAlign: "left",
  },
  tableStyle: {
    display: "table",
    marginTop: "5px",
    marginHorizontal: "10px",
  },
  tableRowStyle: {
    flexDirection: "row",
  },
  firstchild: {
    borderStyle: "solid",
    borderColor: "#000",
    borderBottomColor: "#000",
    borderWidth: 1,
  },
  tableColHeaderStyle: {
    borderStyle: "solid",
    borderColor: "#000",
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: "thick",
  },
  tableColStyle: {
    borderRightStyle: "solid",
    borderRightColor: "#000",
    borderRightWidth: 1,
    borderBottomStyle: "dashed",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    borderTopWidth: "thick",
  },
  tableCellHeaderStyle: {
    textAlign: "center",
    fontFamily: "ArialMT",
    fontSize: "7pt",
    fontWeight: "bold",
  },
  textCell: {
    textAlign: "center",
    fontFamily: "ArialMT",
    fontSize: "7pt",
    marginTop: "2pt",
  },
});

const ActaCalificaciones = ({
  info,
  cicle_name,
  tipo_examen,
  plantilla,
  notas,
}) => {
  const entidad = JSON.parse(sessionStorage.getItem("user"))?.perfil?.entidad;
  const date = new Date();
  const dateString = date.toLocaleDateString("es-ES", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });
  const header = plantilla.find((e) => e.espacio === "header").imagen;
  const footer = plantilla.find((e) => e.espacio === "footer").imagen;
  return (
    <Document>
      <Page
        size="LETTER"
        style={{
          paddingLeft: "0.19cm",
          paddingRight: "0.19cm",
          paddingTop: "0.1cm",
        }}
      >
        <View>
          <Image
            component="img"
            src={header}
            variant="gradient"
            style={{
              height: "1.94cm",
            }}
          />
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <View style={styles.texWrapper}>
            <Text style={styles.textBold}>
              SISTEMA DE ACREDITACIÓN Y CERTIFICACIÓN NORMAL
            </Text>
            {tipo_examen === "Ordinario" && (
              <Text style={styles.textBold}>REGISTRO DE CALIFICACIONES</Text>
            )}
            {(tipo_examen === "Regularización 1" || "Regularización 2") && (
              <Text style={styles.textBold}>Acta de REGULARIZACIONES</Text>
            )}
            <Text style={styles.textBold}>{entidad?.nombre}</Text>
            <Text style={styles.textBold}>
              C.C.T {entidad.clave_del_centro}
            </Text>
            <Text style={styles.textBold}>Departamento de Control Escolar</Text>
            <Text style={styles.textBold}>
              CICLO ESCOLAR: {cicle_name} {paridadNew(info.semestre)}
            </Text>
          </View>
        </View>

        <View
          style={{
            marginTop: "6px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View style={[styles.infoIntermedia, { marginLeft: "1.2cm" }]}>
            <View>
              <Text style={styles.textInfoTitle}>Licenciatura:</Text>
              <Text style={styles.textInfoTitle}>PROFESOR:</Text>
              <Text style={styles.textInfoTitle}>CURSO:</Text>
              <Text style={styles.textInfoTitle}>CLAVE:</Text>
            </View>
            <View style={{ marginTop: "1.5px" }}>
              <Text style={styles.textInfo}>{info.carrera}</Text>
              <Text style={styles.textInfo}>{info.profesor_name}</Text>
              <Text style={styles.textInfo}>{info.asignatura}</Text>
              <Text style={styles.textInfo}>{info.asignatura_clave}</Text>
            </View>
          </View>
          <View style={[styles.infoIntermedia, { marginRight: "2cm" }]}>
            <View>
              <Text style={styles.textInfoTitle}>SEMESTRE:</Text>
              <Text style={styles.textInfoTitle}>GRUPO:</Text>
              <Text style={styles.textInfoTitle}>MODALIDAD:</Text>
              <Text style={styles.textInfoTitle}>TURNO:</Text>
            </View>
            <View style={{ marginTop: "1.5px" }}>
              <Text style={styles.textInfo}>
                {changePalabra(info.semestre)}
              </Text>
              <Text style={styles.textInfo}>{info.grupo_ident}</Text>
              <Text style={styles.textInfo}>ESCOLARIZADA</Text>
              <Text style={styles.textInfo}>MATUTINO</Text>
            </View>
          </View>
        </View>
        {tipo_examen === "Ordinario" && (
          <View style={[styles.tableStyle]}>
            <View style={[styles.tableRowStyle]} fixed>
              <View style={[styles.firstchild, { width: "4%" }]}>
                <Text
                  style={[styles.tableCellHeaderStyle, { marginTop: "4pt" }]}
                >
                  No.
                </Text>
              </View>
              <View style={[styles.tableColHeaderStyle, { width: "13%" }]}>
                <Text
                  style={[styles.tableCellHeaderStyle, { marginTop: "4pt" }]}
                >
                  MATRÍCULA
                </Text>
              </View>
              <View style={[styles.tableColHeaderStyle, { width: "30%" }]}>
                <Text
                  style={[styles.tableCellHeaderStyle, { marginTop: "4pt" }]}
                >
                  NOMBRE y APELLIDOS
                </Text>
              </View>
              <View style={[styles.tableColHeaderStyle, { width: "10%" }]}>
                <Text
                  style={[
                    styles.tableCellHeaderStyle,
                    { marginBottom: 0, lineHeight: 1 },
                  ]}
                >
                  PROMEDIO
                </Text>
                <Text style={[styles.tableCellHeaderStyle]}>FINAL</Text>
              </View>
              <View style={[styles.tableColHeaderStyle, { width: "10%" }]}>
                <Text
                  style={[
                    styles.tableCellHeaderStyle,
                    { marginBottom: 0, lineHeight: 1 },
                  ]}
                >
                  TOTAL
                </Text>
                <Text style={[styles.tableCellHeaderStyle]}>FALTAS</Text>
              </View>
              <View style={[styles.tableColHeaderStyle, { width: "10%" }]}>
                <Text
                  style={[
                    styles.tableCellHeaderStyle,
                    { marginBottom: 0, lineHeight: 1 },
                  ]}
                >
                  TOTAL
                </Text>
                <Text style={[styles.tableCellHeaderStyle]}>ASISTENCIAS</Text>
              </View>
              <View style={[styles.tableColHeaderStyle, { width: "10%" }]}>
                <Text
                  style={[
                    styles.tableCellHeaderStyle,
                    { marginBottom: 0, lineHeight: 1 },
                  ]}
                >
                  %
                </Text>
                <Text style={[styles.tableCellHeaderStyle]}>ASISTENCIAS</Text>
              </View>
              <View style={[styles.tableColHeaderStyle, { width: "13%" }]}>
                <Text
                  style={[styles.tableCellHeaderStyle, { marginTop: "4pt" }]}
                >
                  OBSERVACIONES
                </Text>
              </View>
            </View>

            {notas.map((nota, ind) => {
              return (
                <View
                  key={`${nota.id_dist_estu}`}
                  style={[
                    styles.tableRowStyle,

                    ind % 2 !== 0 ? { backgroundColor: "#eef0f0" } : "",
                  ]}
                  fixed
                >
                  <View
                    style={[
                      styles.tableColStyle,
                      {
                        width: "4%",
                        borderLeftStyle: "solid",
                        borderLeftColor: "#000",
                        borderLeftWidth: 1,
                      },
                    ]}
                  >
                    <Text style={[styles.textCell]}>{ind}</Text>
                  </View>
                  <View style={[styles.tableColStyle, { width: "13%" }]}>
                    <Text style={[styles.textCell]}>
                      {nota.numero_de_control}
                    </Text>
                  </View>
                  <View style={[styles.tableColStyle, { width: "30%" }]}>
                    <Text
                      style={[
                        styles.textCell,
                        {
                          textAlign: "left",
                          marginLeft: "1px",
                        },
                      ]}
                    >
                      {nota.apellido_paterno} {nota.apellido_materno}{" "}
                      {nota.first_name}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.tableColStyle,
                      {
                        width: "10%",
                      },
                    ]}
                  >
                    <Text style={[styles.textCell]}>
                      {nota.ordinario.nota_final ?? "---"}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.tableColStyle,
                      {
                        width: "10%",
                      },
                    ]}
                  >
                    <Text style={[styles.textCell]}>
                      {nota.ordinario.faltas ?? "---"}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.tableColStyle,
                      {
                        width: "10%",
                      },
                    ]}
                  >
                    <Text style={[styles.textCell]}>
                      {nota.ordinario.asistencias ?? "---"}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.tableColStyle,
                      {
                        width: "10%",
                      },
                    ]}
                  >
                    <Text style={[styles.textCell]}>
                      {Math.trunc(nota.ordinario.porcentaje_de_asistencia) ??
                        "---"}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.tableColStyle,
                      {
                        width: "13%",
                      },
                    ]}
                  >
                    <Text style={[styles.textCell]}>
                      {nota.ordinario !== undefined
                        ? nota.ordinario.aprobado
                          ? ""
                          : "No Acreditado"
                        : "---"}
                    </Text>
                  </View>
                </View>
              );
            })}
          </View>
        )}
        {tipo_examen === "Regularización 1" && (
          <View style={[styles.tableStyle, { marginHorizontal: "15px" }]}>
            <View style={[styles.tableRowStyle]} fixed>
              <View style={[styles.firstchild, { width: "5%" }]}>
                <Text
                  style={[styles.tableCellHeaderStyle, { marginTop: "4pt" }]}
                >
                  No.
                </Text>
              </View>
              <View style={[styles.tableColHeaderStyle, { width: "15%" }]}>
                <Text
                  style={[styles.tableCellHeaderStyle, { marginTop: "4pt" }]}
                >
                  MATRÍCULA
                </Text>
              </View>
              <View style={[styles.tableColHeaderStyle, { width: "40%" }]}>
                <Text
                  style={[styles.tableCellHeaderStyle, { marginTop: "4pt" }]}
                >
                  NOMBRE y APELLIDOS
                </Text>
              </View>
              <View style={[styles.tableColHeaderStyle, { width: "20%" }]}>
                <Text
                  style={[
                    styles.tableCellHeaderStyle,
                    { marginBottom: 0, lineHeight: 1 },
                  ]}
                >
                  CALIFICACIÓN
                </Text>
                <Text style={[styles.tableCellHeaderStyle]}>
                  PERÍODO ORDINARIO
                </Text>
              </View>
              <View style={[styles.tableColHeaderStyle, { width: "20%" }]}>
                <Text
                  style={[
                    styles.tableCellHeaderStyle,
                    { marginBottom: 0, lineHeight: 1 },
                  ]}
                >
                  OPORTUNIDAD
                </Text>
                <Text style={[styles.tableCellHeaderStyle]}>1 de 2</Text>
              </View>
            </View>
            {notas
              ?.filter((el) => el.ordinario && !el.ordinario.aprobado)
              .map((nota, ind) => {
                return (
                  <View
                    key={`${nota.id_dist_estu}`}
                    style={[
                      styles.tableRowStyle,
                      ind % 2 !== 0 ? { backgroundColor: "#eef0f0" } : "",
                    ]}
                    fixed
                  >
                    <View
                      style={[
                        styles.tableColStyle,
                        {
                          width: "5%",
                          borderLeftStyle: "solid",
                          borderLeftColor: "#000",
                          borderLeftWidth: 1,
                        },
                      ]}
                    >
                      <Text style={[styles.textCell]}>{ind}</Text>
                    </View>
                    <View style={[styles.tableColStyle, { width: "15%" }]}>
                      <Text style={[styles.textCell]}>
                        {nota.numero_de_control}
                      </Text>
                    </View>
                    <View style={[styles.tableColStyle, { width: "40%" }]}>
                      <Text
                        style={[
                          styles.textCell,
                          {
                            textAlign: "left",
                            marginLeft: "1px",
                          },
                        ]}
                      >
                        {nota.apellido_paterno} {nota.apellido_materno}{" "}
                        {nota.first_name}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableColStyle,
                        {
                          width: "20%",
                        },
                      ]}
                    >
                      <Text style={[styles.textCell]}>
                        {nota.ordinario.nota_final ?? "---"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableColStyle,
                        {
                          width: "20%",
                        },
                      ]}
                    >
                      <Text style={[styles.textCell]}>
                        {nota.regularización_1?.nota_final !== undefined
                          ? nota.regularización_1?.nota_final === 0
                            ? "NP"
                            : nota.regularización_1?.nota_final
                          : ""}
                      </Text>
                    </View>
                  </View>
                );
              })}
          </View>
        )}
        {tipo_examen === "Regularización 2" && (
          <View style={[styles.tableStyle, { marginHorizontal: "15px" }]}>
            <View style={[styles.tableRowStyle]} fixed>
              <View style={[styles.firstchild, { width: "5%" }]}>
                <Text
                  style={[styles.tableCellHeaderStyle, { marginTop: "4pt" }]}
                >
                  No.
                </Text>
              </View>
              <View style={[styles.tableColHeaderStyle, { width: "20%" }]}>
                <Text
                  style={[styles.tableCellHeaderStyle, { marginTop: "4pt" }]}
                >
                  MATRÍCULA
                </Text>
              </View>
              <View style={[styles.tableColHeaderStyle, { width: "40%" }]}>
                <Text
                  style={[styles.tableCellHeaderStyle, { marginTop: "4pt" }]}
                >
                  NOMBRE y APELLIDOS
                </Text>
              </View>
              <View style={[styles.tableColHeaderStyle, { width: "20%" }]}>
                <Text
                  style={[
                    styles.tableCellHeaderStyle,
                    { marginBottom: 0, lineHeight: 1 },
                  ]}
                >
                  CALIFICACIÓN
                </Text>
                <Text style={[styles.tableCellHeaderStyle]}>
                  PERÍODO ORDINARIO
                </Text>
              </View>
              <View style={[styles.tableColHeaderStyle, { width: "20%" }]}>
                <Text
                  style={[
                    styles.tableCellHeaderStyle,
                    { marginBottom: 0, lineHeight: 1 },
                  ]}
                >
                  OPORTUNIDAD
                </Text>
                <Text style={[styles.tableCellHeaderStyle]}>2 de 2</Text>
              </View>
            </View>
            {notas
              ?.filter(
                (el) =>
                  el.ordinario &&
                  !el.ordinario.aprobado &&
                  !el.regularización_1.aprobado
              )
              .map((nota, ind) => {
                return (
                  <View
                    key={`${nota.id_dist_estu}`}
                    style={[
                      styles.tableRowStyle,
                      ind % 2 !== 0 ? { backgroundColor: "#eef0f0" } : "",
                    ]}
                    fixed
                  >
                    <View
                      style={[
                        styles.tableColStyle,
                        {
                          width: "5%",
                          borderLeftStyle: "solid",
                          borderLeftColor: "#000",
                          borderLeftWidth: 1,
                        },
                      ]}
                    >
                      <Text style={[styles.textCell]}>{ind}</Text>
                    </View>
                    <View style={[styles.tableColStyle, { width: "20%" }]}>
                      <Text style={[styles.textCell]}>
                        {nota.numero_de_control}
                      </Text>
                    </View>
                    <View style={[styles.tableColStyle, { width: "40%" }]}>
                      <Text
                        style={[
                          styles.textCell,
                          {
                            textAlign: "left",
                            marginLeft: "1px",
                          },
                        ]}
                      >
                        {nota.apellido_paterno} {nota.apellido_materno}{" "}
                        {nota.first_name}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableColStyle,
                        {
                          width: "20%",
                        },
                      ]}
                    >
                      <Text style={[styles.textCell]}>
                        {nota.ordinario.nota_final ?? "---"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableColStyle,
                        {
                          width: "20%",
                        },
                      ]}
                    >
                      <Text style={[styles.textCell]}>
                        {nota.regularización_2?.nota_final !== undefined
                          ? nota.regularización_2?.nota_final === 0
                            ? "NP"
                            : nota.regularización_2?.nota_final
                          : ""}
                      </Text>
                    </View>
                  </View>
                );
              })}
          </View>
        )}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: "3px",
          }}
        >
          <Text style={[styles.textInfo, { textTransform: "capitalize" }]}>
            F. Impresión: {dateString}
          </Text>
        </View>
        {tipo_examen === "Ordinario" && (
          <View
            style={{
              position: "absolute",
              bottom: "1.8cm",
              left: 0,
              right: 0,
            }}
            wrap={false}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "space-between",
              }}
            >
              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Text style={styles.textInfo}>{info.profesor_name}</Text>
                <Svg height="3" width="300">
                  <Line
                    x1={50}
                    x2={250}
                    y1={0}
                    y2={0}
                    stroke="black"
                    strokeWidth={2}
                  />
                </Svg>
                <Text style={styles.textInfo}>Nombre del Profesor(A)</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Text style={styles.textInfo}> </Text>
                <Svg height="3" width="300">
                  <Line
                    x1={50}
                    x2={250}
                    y1={0}
                    y2={0}
                    stroke="black"
                    strokeWidth={2}
                  />
                </Svg>
                <Text style={styles.textInfo}>Firma del Profesor(A)</Text>
              </View>
            </View>
          </View>
        )}
        {(tipo_examen === "Regularización 1" || "Regularización 2") && (
          <View
            style={{
              position: "absolute",
              bottom: "1.8cm",
              left: 0,
              right: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            wrap={false}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "space-between",
              }}
            >
              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Text style={styles.textInfo}>
                  {entidad?.clave_del_centro === "13DNL0004E"
                    ? "MTRO. RICARDO CARPIO SAGAHÓN"
                    : "MTRA. CASANDRA ALHELI ORTEGA RONDERO"}
                </Text>
                <Svg height="3" width="300">
                  <Line
                    x1={50}
                    x2={250}
                    y1={0}
                    y2={0}
                    stroke="black"
                    strokeWidth={2}
                  />
                </Svg>
                <Text style={styles.textInfo}>JEFE CONTROL ESCOLAR</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Text style={styles.textInfo}>{info.profesor_name}</Text>
                <Svg height="3" width="300">
                  <Line
                    x1={50}
                    x2={250}
                    y1={0}
                    y2={0}
                    stroke="black"
                    strokeWidth={2}
                  />
                </Svg>
                <Text style={styles.textInfo}>PROFESOR (A)</Text>
              </View>
            </View>
            <View
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Text style={styles.textInfo}>
                {entidad?.clave_del_centro === "13DNL0004E"
                  ? "MTRO. JOSÉ CUATEPOTZO COSTEIRA"
                  : "MTRA. MARÍA DE JESÚS LEINES SOLARES"}
              </Text>
              <Svg height={3}>
                <Line
                  x1={200}
                  x2={400}
                  y1={0}
                  y2={0}
                  stroke="black"
                  strokeWidth={2}
                />
              </Svg>
              <Text style={styles.textInfo}>DIRECTOR DEL PLANTEL</Text>
            </View>
          </View>
        )}
        {footer !== null && (
          <View
            style={{
              position: "absolute",
              bottom: "0.2cm",
              left: 0,
              right: 0,
            }}
            wrap={false}
          >
            <Image
              component="img"
              src={footer}
              variant="gradient"
              style={{
                height: "1.5cm",
              }}
            />
          </View>
        )}
      </Page>
    </Document>
  );
};

export default ActaCalificaciones;
