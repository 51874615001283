// Images
import educaciónSinFondo from "assets/images/tecnoschool/educaciónSinFondo.png";
import firmaJefe from "assets/images/tecnoschool/cren_benito/firmaJefe.png";
import cren from "assets/images/tecnoschool/cren_benito/cren.png";
import sierraLogo from "assets/images/tecnoschool/sierra/sierra.png";
import logoderecha from "assets/images/tecnoschool/cren_benito/logoderechaMenos.png";

import {
  Document,
  Page,
  Image,
  Text,
  Font,
  StyleSheet,
  View,
  Svg,
  Line,
} from "@react-pdf/renderer";
import Montserrat from "../../../../assets/fonts/Montserrat/static/Montserrat-Bold.ttf";
import MontserratBoldItalic from "../../../../assets/fonts/Montserrat/static/Montserrat-BoldItalic.ttf";
import Montserrat_Italic from "../../../../assets/fonts/Montserrat/static/Montserrat-Italic.ttf";
import Montserrat_Light from "../../../../assets/fonts/Montserrat/static/Montserrat-Light.ttf";
import Montserrat_Regular from "../../../../assets/fonts/Montserrat/static/Montserrat-Regular.ttf";
import Montserrat_Thin from "../../../../assets/fonts/Montserrat/static/Montserrat-Thin.ttf";
import Montserrat_ThinItalic from "../../../../assets/fonts/Montserrat/static/Montserrat-ThinItalic.ttf";
import { ParidadPorSemestre } from "layouts/escuela/data-tables/grupos/grupoShow";
import { changePalabra } from "helpers/functions";

Font.register({
  family: "Montserrat",
  fonts: [
    { src: Montserrat, fontWeight: "bold" },
    { src: MontserratBoldItalic, fontStyle: "italic", fontWeight: "bold" },
    { src: Montserrat_Italic, fontStyle: "italic" },
    { src: Montserrat_Light, fontWeight: "light" },
    { src: Montserrat_Regular, fontWeight: "normal", fontStyle: "normal" },
    { src: Montserrat_Thin, fontWeight: "thin" },
    { src: Montserrat_ThinItalic, fontStyle: "italic", fontWeight: "thin" },
  ],
});

const styles = StyleSheet.create({
  textBold: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "10pt",
    lineHeight: "1.7",
    textTransform: "uppercase",
  },
  textInfoTitle: {
    fontFamily: "Montserrat",
    fontSize: "9pt",
    textTransform: "uppercase",
    lineHeight: "1.2",
    fontWeight: "bold",
  },
  textInfo: {
    fontFamily: "Montserrat",
    fontSize: "9pt",
    textTransform: "uppercase",
    marginLeft: "5px",
    lineHeight: "1.2",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  textFooterRed: {
    fontFamily: "Montserrat",
    fontSize: "7pt",
    textTransform: "uppercase",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#880027",
  },
  texWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "20px",
  },
  textDetallesFooter: {
    fontFamily: "Montserrat",
    fontSize: "6pt",
    lineHeight: "1.2",
    fontWeight: "normal",
    direction: "rtl",
  },
  infoIntermedia: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    textAlign: "left",
  },
  botton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  bottonOtro: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  bottonSpaceEvenly: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  bottonCertificado: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  tableStyle: {
    display: "flex",
    alignItems: "center",
    margin: "12px -10px",
    width: "104%",
  },
  tableRowStyle: {
    flexDirection: "row",
  },
  firstchild: {
    borderStyle: "solid",
    borderColor: "#000",
    borderBottomColor: "#000",
    borderWidth: 1,
  },
  tableColHeaderStyle: {
    borderStyle: "solid",
    borderColor: "#000",
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: "thick",
  },
  tableColStyle: {
    borderRightStyle: "solid",
    borderRightColor: "#000",
    borderRightWidth: 1,
    borderBottomStyle: "dashed",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    borderTopWidth: "thick",
  },
  tableCellHeaderStyle: {
    textAlign: "center",
    fontFamily: "Montserrat",
    fontSize: "9pt",
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  textCell: {
    textAlign: "center",
    fontFamily: "Montserrat",
    fontSize: "9pt",
    textTransform: "uppercase",
    fontStyle: "normal",
    fontWeight: "normal",
    marginTop: "1px",
  },
});

const BoletaSinFirmar = ({ boleta, estudiante, plantilla }) => {
  const entidad = JSON.parse(sessionStorage.getItem("user"))?.perfil?.entidad;
  const clave_centro = entidad.clave_del_centro;
  const header = plantilla.find((e) => e.espacio === "header").imagen;
  const footer = plantilla.find((e) => e.espacio === "footer").imagen;
  return (
    <Document>
      <Page
        size="LETTER"
        style={{
          paddingLeft: "0.19cm",
          paddingRight: "0.19cm",
          paddingTop: "0.1cm",
        }}
      >
        <View
          style={{
            display: "flex",
            marginTop: "5mm",
            alignItems: "center",
          }}
        >
          <Image
            component="img"
            src={header}
            variant="gradient"
            style={{
              height: "2cm",
              width: "13.4cm",
            }}
          />
        </View>
        <View
          style={[
            styles.texWrapper,
            { alignItems: "flex-start", marginLeft: "1.1cm" },
          ]}
        >
          <Text style={styles.textBold}>
            Subsecretaría de Educación Media Superior y Superior
          </Text>
          <Text style={styles.textBold}>
            Dirección General de Formación y Superación Docente
          </Text>
          <Text style={styles.textBold}>Dirección de Educación Normal</Text>
          <Text style={styles.textBold}>
            {clave_centro !== "13DLN0002G"
              ? `Centro Regional de Educación Normal “Benito Juárez”`
              : `Escuela Normal Sierra Hidalguense`}
          </Text>
        </View>
        <View style={styles.texWrapper}>
          <Text style={styles.textBold}>
            SISTEMA DE ACREDITACIÓN Y CERTIFICACIÓN NORMAL
          </Text>
          <Text
            style={[
              styles.textBold,
              {
                marginTop: `${clave_centro === "13DNL0004E" ? "-5px" : ""} `,
              },
            ]}
          >
            BOLETA DE CALIFICACIONES SEMESTRALES
          </Text>
          <Text style={styles.textBold}>
            CICLO ESCOLAR: {estudiante.ciclo}{" "}
            {ParidadPorSemestre(estudiante.semestre)}
          </Text>
          <Text style={styles.textBold}>DEPARTAMENTO DE CONTROL ESCOLAR</Text>
        </View>
        <View
          style={{
            marginTop: "30px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View style={[styles.infoIntermedia, { marginLeft: "1.1cm" }]}>
            <View>
              {clave_centro === "13DLN0002G" && (
                <Text style={[styles.textInfoTitle]}>Plantel:</Text>
              )}
              <Text style={[styles.textInfoTitle]}>Nombre:</Text>
              <Text style={[styles.textInfoTitle]}>Semestre:</Text>
              <Text style={[styles.textInfoTitle]}>Turno:</Text>
            </View>
            <View>
              {clave_centro === "13DLN0002G" && (
                <Text style={[styles.textInfo]}>{entidad.nombre}</Text>
              )}
              <Text style={styles.textInfo}>
                {estudiante.apellido_paterno} {estudiante.apellido_materno}{" "}
                {estudiante.nombre}
              </Text>
              <Text style={styles.textInfo}>
                {changePalabra(estudiante.semestre)}
              </Text>
              <Text style={styles.textInfo}>Matutino</Text>
            </View>
          </View>
          <View style={[styles.infoIntermedia, { marginRight: "1.4cm" }]}>
            <View>
              <Text style={[styles.textInfoTitle]}>
                {clave_centro !== "13DLN0002G" ? "Matricula:" : "CCT"}
              </Text>
              {clave_centro === "13DLN0002G" && (
                <Text style={[styles.textInfoTitle]}> </Text>
              )}
              <Text style={[styles.textInfoTitle]}>Grupo:</Text>
              <Text style={[styles.textInfoTitle]}>
                {clave_centro !== "13DLN0002G" ? "CCT:" : "No. de Matricula"}
              </Text>
            </View>
            <View>
              <Text style={styles.textInfo}>
                {clave_centro !== "13DLN0002G"
                  ? estudiante.numero_de_control
                  : clave_centro}
              </Text>
              {clave_centro === "13DLN0002G" && (
                <Text style={[styles.textInfoTitle]}> </Text>
              )}
              <Text style={styles.textInfo}>{estudiante.grupo}</Text>
              <Text style={styles.textInfo}>
                {clave_centro !== "13DLN0002G"
                  ? clave_centro
                  : estudiante.numero_de_control}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.texWrapper}>
          <Text style={styles.textBold}>{estudiante.carrera}</Text>
        </View>
        <View style={[styles.tableStyle]}>
          <View style={[styles.tableRowStyle]} fixed>
            <View style={[styles.firstchild, { width: "5%" }]}>
              <Text style={[styles.tableCellHeaderStyle, { marginTop: "4pt" }]}>
                No.
              </Text>
            </View>
            <View style={[styles.tableColHeaderStyle, { width: "62%" }]}>
              <Text style={[styles.tableCellHeaderStyle, { marginTop: "4pt" }]}>
                {clave_centro !== "13DLN0002G" ? `ASIGNATURA` : `CURSOS`}
              </Text>
            </View>
            <View style={[styles.tableColHeaderStyle, { width: "20%" }]}>
              <Text
                style={[
                  styles.tableCellHeaderStyle,
                  { marginBottom: 0.5, lineHeight: 1.5 },
                ]}
              >
                {clave_centro !== "13DLN0002G"
                  ? `CALIFICACIÓN`
                  : `CALIFICACIONES`}
              </Text>
              {clave_centro === "13DLN0002G" && (
                <Text style={[styles.tableCellHeaderStyle]}>Número</Text>
              )}
            </View>
          </View>
          {boleta.map((nota, ind) => {
            return (
              <View
                key={`nota-${ind}`}
                style={[
                  styles.tableRowStyle,
                  ind % 2 !== 0 ? { backgroundColor: "#eef0f0" } : "",
                ]}
                fixed
              >
                <View
                  style={[
                    styles.tableColStyle,
                    {
                      width: "5%",
                      borderLeftStyle: "solid",
                      borderLeftColor: "#000",
                      borderLeftWidth: 1,
                    },
                  ]}
                >
                  <Text style={[styles.textCell]}>{ind}</Text>
                </View>
                <View style={[styles.tableColStyle, { width: "62%" }]}>
                  <Text style={[styles.textCell]}>{nota.asignatura}</Text>
                </View>
                <View style={[styles.tableColStyle, { width: "20%" }]}>
                  <Text style={[styles.textCell]}>{nota.nota}</Text>
                </View>
              </View>
            );
          })}
        </View>
        <Text style={[styles.textInfoTitle, { marginLeft: "50%" }]}>
          Promedio por Semestre:{"      "}
          {(
            boleta.reduce((acc, nota) => acc + parseInt(nota.nota), 0) /
            boleta.length
          ).toFixed(1)}
        </Text>
        <View
          style={{
            position: "absolute",
            bottom: "1.8cm",
            left: 0,
            right: "15px",
          }}
        >
          <View style={[styles.bottonOtro, { marginBottom: "25px" }]}>
            {clave_centro === "13DNL0004E" ? (
              <Image
                className="firma"
                src={firmaJefe}
                variant="gradient"
                style={{
                  maxWidth: "4cm",
                  maxHeight: "4cm",
                  marginBottom: "5px",
                }}
              />
            ) : (
              <View
                style={{
                  maxWidth: "4cm",
                  maxHeight: "4cm",
                  marginBottom: "5px",
                }}
              ></View>
            )}
            <Text
              style={[
                styles.textInfoTitle,
                { fontSize: "12pt", textAlign: "center" },
              ]}
            >
              {clave_centro === "13DNL0004E"
                ? "MTRO. RICARDO CARPIO SAGAHÓN"
                : "LIC. CASANDRA ALHELI ORTEGA RONDERO"}
            </Text>
            <Svg height={3}>
              <Line
                x1={150}
                x2={450}
                y1={0}
                y2={0}
                stroke="black"
                strokeWidth={2}
              />
            </Svg>
            <Text
              style={[
                styles.textInfoTitle,
                { fontSize: "12pt", textAlign: "center", color: "#bc0000" },
              ]}
            >
              {clave_centro === "13DNL0004E"
                ? "JEFE DEL DPTO. CONTROL ESCOLAR"
                : "JEFE DPTO. CONTROL ESCOLAR"}
            </Text>
          </View>
        </View>
        {footer !== null && (
          <View
            style={{
              position: "absolute",
              bottom: "0.2cm",
              left: 0,
              right: 0,
            }}
            wrap={false}
          >
            <Image
              component="img"
              src={footer}
              variant="gradient"
              style={{
                height: "1.5cm",
              }}
            />
          </View>
        )}
      </Page>
    </Document>
  );
};

export default BoletaSinFirmar;
