import ModalBase from "components/ModalBase/modal_base";
import FormField from "components/FormField";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  useCreateEvaluacionesMutation,
  useEditEvaluacionesMutation,
} from "services";
import { useCloseModal } from "hooks/useCloseModal";
import { Grid } from "@mui/material";
import React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Encoder } from "../../helpers/encoder";
import { normalizarCadena } from "../../helpers/functions";
import { useParams } from "react-router-dom";

function calcularAsist(asistencias, faltas) {
  return (asistencias / (asistencias + faltas)) * 100;
}

const CalificarExamen = ({
  open,
  handleClose,
  distribucion_profesor,
  distribucion_estudiante,
  estudiantes,
  edit,
}) => {
  const [actual, setActual] = React.useState(distribucion_estudiante);
  const [toSend, setToSend] = React.useState([]);
  const { params } = useParams();
  const { examen } = Encoder.decodeBase64ToObject(decodeURIComponent(params));

  const [createEvaluaciones, { isSuccess, isError, isLoading, error }] =
    useCreateEvaluacionesMutation();
  const [
    editEvaluaciones,
    {
      isSuccess: isSuccessE,
      isError: isErrorE,
      isLoading: isLoadingE,
      error: errorE,
    },
  ] = useEditEvaluacionesMutation();

  React.useEffect(() => {
    if (!edit) setActual(distribucion_estudiante);
    else setActual(edit);
  }, [distribucion_estudiante]);

  useCloseModal(
    isLoading,
    isSuccess,
    isError,
    error,
    "Calificación(es) asignada(s) correctamente",
    handleClose,
  );

  useCloseModal(
    isLoadingE,
    isSuccessE,
    isErrorE,
    errorE,
    "Calificación editada correctamente",
    handleClose,
  );

  const initialValues = {
    distribucion_profesor: distribucion_profesor ?? "",
    distribucion_estudiante: distribucion_estudiante?.url_dist_estu ?? "",
    nota_final: 0,
    ...(!examen.anteriorName
      ? {
          faltas: 0,
          asistencias: 0,
        }
      : {}),
    examen: examen.url,
  };

  const validationSchemas = Yup.object().shape({
    nota_final: Yup.number()
      .required("Debe introducir una nota final")
      .test("validar-nota", "La nota debe estar entre 5 y 10 ó 0", (value) => {
        if (value === 0) {
          return true;
        }
        return Yup.number().min(5).max(10).isValidSync(value);
      }),
    ...(!examen.anterior
      ? {
          faltas: Yup.number()
            .required("Debe introducir una cantidad de faltas")
            .min(0)
            .max(99, "La cantidad de faltas debe ser menor a 100"),
          asistencias: Yup.number()
            .required("Debe introducir una cantidad de asistencias")
            .min(0)
            .max(99, "La cantidad de asistencias debe ser menor a 100"),
        }
      : {}),
  });

  const submitForm = async (toSend, actions) => {
    if (!edit) {
      if (toSend.length > 0) {
        await Promise.all(
          toSend.map((el) => {
            if (
              calcularAsist(Number(el.asistencias), Number(el.faltas)) < 85 &&
              !examen.anteriorName
            ) {
              el.nota_final = 5;
            }
            createEvaluaciones(el);
          }),
        );
      }
    } else {
      if (
        calcularAsist(Number(toSend.asistencias), Number(toSend.faltas)) < 85 &&
        !examen.anteriorName
      ) {
        toSend.nota_final = 5;
      }

      await editEvaluaciones({
        id: edit[normalizarCadena(examen.name)].id_dist_eval,
        ...Object.fromEntries(
          Object.entries(toSend).filter(
            ([k, v]) =>
              !(
                edit[k] === v ||
                k === "distribucion_profesor" ||
                k === "distribucion_estudiante"
              ),
          ),
        ),
      });
    }
    if (isSuccess || isSuccessE) {
      actions.setSubmitting(false);
      actions.resetForm();
      setToSend([]);
      setActual({});
    }
  };

  const handleSubmit = (values, actions) => {
    if (!edit) {
      if (
        !toSend.some((item) => JSON.stringify(item) === JSON.stringify(values))
      ) {
        submitForm([...toSend, values], actions);
      }
    } else {
      submitForm(values, actions);
    }
  };

  const handleNext = (values) => {
    setToSend([...toSend, values]);
    setActual(estudiantes[estudiantes.indexOf(actual) + 1]);
  };

  return (
    <ModalBase open={open} handleClose={handleClose}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={12}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchemas}
          >
            {({
              values,
              errors,
              touched,
              isValid,
              handleBlur,
              setFieldValue,
            }) => {
              React.useEffect(() => {
                if (!edit) {
                  setFieldValue(
                    "distribucion_estudiante",
                    actual?.url_dist_estu ?? 0,
                  );
                }
                if (!examen.anteriorName) {
                  setFieldValue(
                    "faltas",
                    actual[normalizarCadena(examen.anteriorName)]?.faltas ?? 0,
                    true,
                  );
                  setFieldValue(
                    "asistencias",
                    actual[normalizarCadena(examen.anteriorName)]
                      ?.asistencias ?? 0,
                    true,
                  );
                }
                setFieldValue(
                  "nota_final",
                  actual[normalizarCadena(examen.anteriorName)]?.nota_final ??
                    0,
                  true,
                );
              }, [actual]);
              return (
                <Form key={"asignacion_notas"} id={"notas_notas"}>
                  <MDBox p={0.5}>
                    <MDBox
                      lineHeight={0}
                      display="flex"
                      gap={1}
                      justifyContent={"center"}
                    >
                      <MDTypography variant="h5" mb={3} color="dark">
                        Asignar nota a:
                      </MDTypography>
                      <MDTypography variant="h5" color="secondary" mb={3}>
                        {`${actual?.apellido_paterno} ${actual?.apellido_materno} ${actual?.first_name}`}
                      </MDTypography>
                    </MDBox>
                    <MDBox mt={1.625}>
                      <Grid
                        container
                        spacing={3}
                        display={"flex"}
                        justifyContent={"center"}
                      >
                        <Grid item xs={12} sm={6}>
                          <FormField
                            type={"tel"}
                            label={"Nota Final"}
                            onBlur={handleBlur}
                            name={"nota_final"}
                            value={Number(values.nota_final)}
                            error={errors.nota_final && touched.nota_final}
                            success={
                              (values.nota_final >= 5 ||
                                values.nota_final === 0) &&
                              !errors.nota_final
                            }
                            onInput={(e) => {
                              let value = ("" + e.target.value).replace(
                                /[^(\d|\.)]/g,
                                "",
                              );
                              e.target.value = isNaN(Number(value))
                                ? 0
                                : Number(value).toFixed();
                            }}
                          />
                        </Grid>
                      </Grid>
                    </MDBox>

                    <MDBox
                      mt={2}
                      width="100%"
                      display="flex"
                      justifyContent="space-between"
                    >
                      {!edit && (
                        <MDButton
                          disabled={
                            !isValid ||
                            estudiantes.indexOf(actual) ===
                              estudiantes.length - 1
                          }
                          type="button"
                          onClick={() => handleNext(values)}
                          variant="gradient"
                          color="primary"
                        >
                          siguiente
                        </MDButton>
                      )}
                      <MDButton
                        disabled={!isValid}
                        type="submit"
                        variant="gradient"
                        color="dark"
                      >
                        aceptar
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </ModalBase>
  );
};

export default CalificarExamen;
