import { Icon, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useLazyGetTaskQuery, useMakeHistorialMutation } from "services";

const HistorialComponentCalificaciones = ({ examen, grupos }) => {
  const [historial] = useMakeHistorialMutation();
  const [triggerTarea] = useLazyGetTaskQuery();
  const [cargando, setCargando] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const llamarHistorial = async (body) => {
    try {
      const response = await historial({
        filter_params: {
          query: "{id}",
        },
        body: body,
      }).unwrap();

      return response;
    } catch (error) {
      enqueueSnackbar(`Hubo algún error generando el historial`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const handleClickHistorial = async () => {
    setCargando(true);
    try {
      const response = await llamarHistorial({
        carrera_grupos: grupos,
        examen: examen.url,
      });
      peticionTarea(response.id, examen.nombre);
    } catch (error) {
      enqueueSnackbar(`Hubo algún error generando el historial`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const peticionTarea = async (id, nombre) => {
    triggerTarea(id)
      .unwrap()
      .then((res) => {
        if (res.estado === "COMPLETED") {
          descargarCSV(res.resultado, nombre);
        } else if (res.estado === "ERROR") {
          enqueueSnackbar(`Hubo algún error generando el historial`, {
            variant: "error",
            autoHideDuration: 3000,
          });
        } else {
          setTimeout(() => {
            peticionTarea(id, nombre);
          }, [10000]);
        }
      });
  };

  const descargarCSV = (resultado, nombre) => {
    setCargando(false);
    const csvContent = "\uFEFF" + resultado;
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `Historial ${nombre}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <MDBox>
      <Tooltip
        placement="top"
        title="Pedir el historial de notas de los grupos correspondientes a la carrera mostrada para este examen"
      >
        <MDButton
          variant={"gradient"}
          color={"secondary"}
          size="medium"
          onClick={handleClickHistorial}
          sx={{ marginRight: "10px" }}
          loading={cargando}
        >
          Historial
        </MDButton>
      </Tooltip>
    </MDBox>
  );
};

export default HistorialComponentCalificaciones;
