/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";

// NewUser page components
import FormField from "components/FormField";

import NotFound404 from "assets/images/404.jpg";

import MDImage from "components/MDImage/image";
import { Autocomplete, Tooltip } from "@mui/material";
import { useGetGeneroQuery } from "services";
import MenuItem from "@mui/material/MenuItem";
import { useSnackbar } from "notistack";
import {
  ESTADOS_ESTUDIANTES,
  TIPOS_SANGRES,
} from "../../../../../../helpers/global_vars";

const style = {
  width: "100%",
  cursor: "pointer",
  borderRadius: "0.5rem",
  marginTop: "0.5rem",
  marginLeft: "0.5rem",
};

const icon_style = {
  position: "absolute",
  left: "50%",
  top: "50%",
  fontSize: "30px",
  transform: "translate(-50%,-50%)",
};

function Specifics({ formData }) {
  const {
    formField,
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    setFieldError,
  } = formData;
  const { genero, tipo_sangre, foto } = formField;
  const {
    foto: fotoV,
    genero: generoV,
    tipo_sangre: tipo_sangreV,
    estado: estadoV,
  } = values;

  const { data: generos } = useGetGeneroQuery(undefined, {
    refetchOnReconnect: true,
  });
  const { enqueueSnackbar } = useSnackbar();
  const handleChangeFoto = (e) => {
    const file = e.target.files[0];
    const maxSize = 1024 * 1024; // 250 KB

    if (file.size > maxSize) {
      enqueueSnackbar("El tamaño de la imagen no debe ser mayor a 1 MB", {
        variant: "error",
        autoHideDuration: 4000,
      });
      return;
    } else {
      setFieldValue(foto.name, e.target.files[0]);
    }
  };

  const imageFail = {
    image: NotFound404,
    alt: "No existe",
  };

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormField
              select
              id="genero"
              value={generoV}
              label={genero.label}
              name={genero.name}
              error={errors.genero && touched.genero}
              success={generoV.length > 0 && !errors.genero}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem key={""} value={""}>
                No Seleccionado
              </MenuItem>
              {generos?.map((genero) => (
                <MenuItem key={genero.id_genero} value={genero.url}>
                  {genero.genero}
                </MenuItem>
              ))}
            </FormField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              select
              id="tipo_sangre"
              value={tipo_sangreV}
              label={tipo_sangre.label}
              name={tipo_sangre.name}
              error={errors.tipo_sangre && touched.tipo_sangre}
              success={tipo_sangreV?.length > 0 && !errors.tipo_sangre}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem key={""} value={""}>
                No Seleccionado
              </MenuItem>
              {TIPOS_SANGRES?.map((tipo_sangre) => (
                <MenuItem key={tipo_sangre} value={tipo_sangre}>
                  {tipo_sangre}
                </MenuItem>
              ))}
            </FormField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              id="distribuir-autocomplete-estados"
              options={ESTADOS_ESTUDIANTES}
              onChange={(event, option) => {
                if (option) {
                  // Si se seleccionó una opción, establece la URL en el campo correspondiente del formulario
                  setFieldValue(formField?.estado.name, option);
                } else {
                  // Si se borró la selección, establece la URL en null
                  setFieldValue(formField?.estado.name, "");
                }
              }}
              value={estadoV}
              renderInput={(params) => (
                <FormField
                  id="distribucion"
                  name={"distribucion"}
                  label="Estado"
                  {...params}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} display="flex" justifyContent={"center"}>
          <Grid item xs={12} mt={4}>
            <MDTypography
              variant="h6"
              fontWeight="bold"
              flex
              justifyContent="center"
              textAlign="center"
            >
              Foto:
            </MDTypography>
            <MDBox
              mt={1.625}
              display="flex"
              flexDirection={"column"}
              alignItems="center"
              justifyContent="center"
            >
              <Tooltip
                placement="top"
                title={!fotoV ? "Agregar imagen" : "Cambiar Foto"}
              >
                <MDBox
                  style={{
                    position: "relative",
                    width: "200px",
                    cursor: "pointer",
                  }}
                >
                  {!fotoV ? (
                    <Icon fontSize="medium" style={icon_style}>
                      image_search
                    </Icon>
                  ) : null}
                  <MDImage
                    id="profile-image"
                    htmlFor="profile-image"
                    disabled={false}
                    style={style}
                    src={
                      fotoV && typeof fotoV === "object"
                        ? URL.createObjectURL(fotoV)
                        : fotoV
                    }
                    imageTypes={foto.accept}
                    handleChange={handleChangeFoto}
                  />
                </MDBox>
              </Tooltip>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for Address
Specifics.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Specifics;
