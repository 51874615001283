/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import Sinfondo from "assets/images/tecnoschool/Sinfondo.png";
import fondo from "assets/images/fondos/fondoPizarra.jpg";
import { HOME_URL, RESET_PASSWORD } from "config/bases_url";
import { useLoginMutation } from "services/principal/auth.service";
import FormField from "components/FormField";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { CircularProgress, Icon } from "@mui/material";
import { style_icon } from "helpers/global_vars";
import InputAdornment from "@mui/material/InputAdornment";
import { EMAIL_REGEX } from "helpers/regexs";
import { useRedirectForm } from "hooks/useRedirectForm";
import { useAuthRedirect } from "../../../../hooks/useAuthRedirect.";

function Basic() {
  const [
    login,
    {
      isLoading: isLoadingM,
      isError: isErrorM,
      error: errorM,
      isSuccess: isSuccessM,
    },
  ] = useLoginMutation();

  const [shown, setShown] = useState(false);

  useRedirectForm(
    isLoadingM,
    isSuccessM,
    isErrorM,
    errorM,
    "Sesión iniciada correctamente",
    HOME_URL
  );

  useAuthRedirect();

  const handleSubmit = async (values, actions) => {
    login(values);
  };

  const handleChangeShown = () => setShown(!shown);

  return (
    <BasicLayout image={fondo}>
      <Card>
        <MDBox
          component="img"
          src={Sinfondo}
          variant="gradient"
          bgColor="dark"
          borderRadius="xxl"
          mx={2}
          mt={-11}
          p={2}
          mb={1}
          sx={{ boxShadow: "0rem 0rem 0.625rem 0.3rem rgb(0 0 0 / 40%)" }}
        />
        <MDBox pt={3} pb={3} px={3}>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={handleSubmit}
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("Debe proporcionar una dirección de correo válida")
                .matches(
                  EMAIL_REGEX,
                  "Debe proporcionar una dirección de correo válida"
                )
                .required("Debe proporcionar una dirección de correo"),
              password: Yup.string().required(
                "Debe proporcionar una contraseña"
              ),
            })}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              isValid,
            }) => {
              return (
                <Form id="loginForm" autoComplete="off">
                  <MDBox mb={2}>
                    <FormField
                      type="email"
                      label="Correo"
                      name="email"
                      variant="outlined"
                      value={values.email}
                      error={errors.email && touched.email}
                      success={!!values.email.match(EMAIL_REGEX)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="username"
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <FormField
                      type={shown ? "text" : "password"}
                      label="Contraseña"
                      variant="outlined"
                      name="password"
                      value={values.password}
                      error={errors.password && touched.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="current-password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {values.password.length !== 0 ? (
                              <Icon
                                style={style_icon}
                                onClick={handleChangeShown}
                                fontSize="small"
                                variant="outlined"
                              >
                                {shown ? "visibility_off" : "visibility"}
                              </Icon>
                            ) : null}
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mt={3} mb={1} justifyItems={"center"}>
                    <MDButton
                      disabled={!isValid || isLoadingM}
                      variant="gradient"
                      color="dark"
                      type="submit"
                      fullWidth
                      loading={isLoadingM}
                    >
                      Iniciar Sesión
                    </MDButton>
                  </MDBox>
                </Form>
              );
            }}
          </Formik>

          <MDBox mt={2} textAlign="center">
            <MDTypography variant="button" color="text">
              <MDTypography
                component={Link}
                to={RESET_PASSWORD}
                variant="gradient"
                color="dark"
                fontWeight="medium"
                textGradient
              >
                ¿Olvidaste tu contraseña?
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
