import * as React from "react";
import { useState } from "react";
import { Card, CircularProgress, Grid, Icon, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
  useDeleteAsignaturasPorProfesorMutation,
  useDeleteBoletaMutation,
  useDeleteEstudiantesPorGrupoMutation,
  useGetAsignaturasPorProfesorQuery,
  useGetBoletasQuery,
  useGetCarrerasPorGrupoByIdQuery,
  useGetEstudiantesPorGrupoQuery,
} from "services";
import DataTable from "examples/Tables/DataTable";
import ComponentsPrivatization, {
  removeColumns,
} from "config/component_privatization";
import { AUXILIAR_DOCENTE, COORDINADOR_DOCENTE, FIRMANTE_BOLETA } from "roles";
import { style_icon } from "helpers/global_vars";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRedirectForm } from "hooks/useRedirectForm";
import MDButton from "components/MDButton";
import useModalHandler from "../../../../hooks/useModalHandler";
import { useSnackbar } from "notistack";
import ModalFirmar from "components/ModalFirmar";
import CheckBox from "../../../../components/CheckBox";
import { generarURL } from "helpers/functions";
import BoletaFirmada from "../../pdf/reactPdf/boleta";
import { pdf } from "@react-pdf/renderer";
import { QRCodeCanvas } from "qrcode.react";
import BoletaSinFirmar from "layouts/escuela/pdf/reactPdf/boletSinFirmar";
import { useSelector } from "react-redux";
import { calificaciones } from "slices";
import JSZip from "jszip";
import { handleToast } from "../../../../helpers/handleToast";
import { useGetTemplatesQuery } from "services";

export const ParidadPorSemestre = (semestre) =>
  !esImpar(semestre) ? "PAR" : "NON";

const GrupoShow = () => {
  const navigate = useNavigate();
  const [isDownloading, setIsDownloading] = useState(false);

  const { id, ciclo_id, cicle_name } = useParams();

  const [selectedItems, setSelectedItems] = React.useState([]);
  const [checkedAll, setCheckedAll] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { open, handleOpen, handleClose } = useModalHandler();

  const { cicloActivo } = useSelector(calificaciones);

  React.useEffect(() => {
    if (!checkedAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(est_por_grupo.filter((el) => el.firmado === false));
    }
  }, [checkedAll]);

  const { data: grupoById } = useGetCarrerasPorGrupoByIdQuery(id);

  const {
    data: plantillaPDF,
    isLoading: isLoadingP,
    isSuccess: isSuccessP,
  } = useGetTemplatesQuery({
    filter_params: {
      tipo: "boleta",
    },
  });

  React.useEffect(() => {
    if (
      isSuccessP &&
      !plantillaPDF?.[0].imagenes.find((e) => e.espacio === "header").imagen
    ) {
      enqueueSnackbar(
        `Para mostrar la boleta es necesario que actualice su modelo-pdf de boleta`,
        {
          variant: "warning",
          autoHideDuration: 3000,
        }
      );
    }
  }, [isLoadingP]);

  const { data: asig_por_prof, isLoading: isLoadingAP } =
    useGetAsignaturasPorProfesorQuery(
      {
        filter_params: {
          distribucion_grupo: id,
          ordering:
            "asignatura__nombre,profesor__user__apellido_paterno,profesor__user__apellido_materno,profesor__user__first_name, asignatura__nombre",
          query:
            "{id,url,asignatura{nombre},profesor{user{first_name, apellido_paterno, apellido_materno}},distribucion_grupo{id,grupo{identificador}}}",
        },
        shape: {
          id: "id",
          url: "url",
          grupo_ident: "distribucion_grupo.grupo.identificador",
          asignatura_name: "asignatura.nombre",
          profesor_name:
            "profesor.user.apellido_paterno profesor.user.apellido_materno profesor.user.first_name",
          dist_grupo_id: "distribucion_grupo.id",
        },
      },
      {
        refetchOnReconnect: true,
      }
    );

  const { data: est_por_grupo } = useGetEstudiantesPorGrupoQuery(
    {
      filter_params: {
        distribucion_grupo: id,
        estado: "Activo",
        ordering:
          "estudiante__user__apellido_paterno,estudiante__user__apellido_materno,estudiante__user__first_name",
        query:
          "{notas,url,cadena_unica,firmado,estudiante{user{first_name,apellido_paterno,apellido_materno},numero_de_control},id,distribucion_grupo{id,ciclo{nombre},ciclo_interno{numero_de_ciclo,carrera{nombre}},grupo{identificador}}}",
      },
      shape: {
        nombre: "estudiante.user.first_name",
        apellido_materno: "estudiante.user.apellido_materno",
        apellido_paterno: "estudiante.user.apellido_paterno",
        numero_de_control: "estudiante.numero_de_control",
        id: "id",
        dist_grupo_id: "distribucion_grupo.id",
        carrera: "distribucion_grupo.ciclo_interno.carrera.nombre",
        ciclo: "distribucion_grupo.ciclo.nombre",
        semestre: "distribucion_grupo.ciclo_interno.numero_de_ciclo",
        grupo: "distribucion_grupo.grupo.identificador",
        cadena_unica: "cadena_unica",
        firmado: "firmado",
        notas: "notas",
        url: "url",
      },
    },
    {
      refetchOnReconnect: true,
    }
  );

  const { data: getBoleta } = useGetBoletasQuery({
    filter_params: {
      signed_object__distribucion_grupo: id,
      query:
        "{id,firmante{cargo,firma,user{first_name,apellido_paterno,apellido_materno}},fecha_creacion,sello,signed_object{id}}",
    },
    shape: {
      nombre: "firmante.user.first_name",
      paterno: "firmante.user.apellido_paterno",
      materno: "firmante.user.apellido_materno",
      cargo: "firmante.cargo",
      firma: "firmante.firma",
      fecha: "fecha_creacion",
      sello: "sello",
      id: "id",
      dist_est: "signed_object.id",
    },
  });

  const [deleteFirmaBoleta] = useDeleteBoletaMutation();

  const [
    deleteAsigPorProfesor,
    {
      isSuccess: isSuccessD,
      isLoading: isLoadingD,
      isError: isErrorD,
      error: errorD,
    },
  ] = useDeleteAsignaturasPorProfesorMutation();

  const [
    deleteEstPorGrupo,
    {
      isSuccess: isSuccessExG,
      isLoading: isLoadingExG,
      isError: isErrorExG,
      error: errorExG,
    },
  ] = useDeleteEstudiantesPorGrupoMutation();

  useRedirectForm(
    isLoadingExG,
    isSuccessExG,
    isErrorExG,
    errorExG,
    "Eliminado el estudiante de ese grupo",
    `/ciclos/${cicle_name}/grupos`
  );

  useRedirectForm(
    isLoadingD,
    isSuccessD,
    isErrorD,
    errorD,
    "Eliminado el profesor con su asignatura de ese grupo",
    `/ciclos/${cicle_name}/grupos`
  );

  const tableAsignaturaPorProfesor = {
    columns: [
      { Header: "Asignatura", accessor: "asignatura_name" },
      { Header: "Profesor", accessor: "profesor_name" },
      {
        Header: "Acciones",
        accessor: "actions",
        width: "1%",
        Cell: ({ row }) => {
          const { original } = row;

          return (
            <MDBox display="flex">
              <ComponentsPrivatization
                permitted={[COORDINADOR_DOCENTE, AUXILIAR_DOCENTE]}
              >
                <MDBox px={0.2}>
                  <Tooltip
                    placement="top"
                    title="Editar la asociación de profesor-asignatura al grupo (puede cambiar el profesor o la asignatura)"
                  >
                    <Icon
                      style={style_icon}
                      onClick={() => handleClickEditProf(original)}
                      fontSize="small"
                    >
                      edit
                    </Icon>
                  </Tooltip>
                </MDBox>
                <MDBox px={0.2}>
                  <Tooltip
                    placement="top"
                    title="Eliminar la asociación de profesor-asignatura del grupo (no existirá ese profesor dando esa asignatura en el grupo actual)"
                  >
                    <Icon
                      style={style_icon}
                      onClick={() =>
                        handleToast({
                          title: "¿Estás seguro?",
                          text: `¡Si eliminas la asignatura: ${original.asignatura_name}, el profesor: ${original.profesor_name} no la dará más en el grupo: ${original.grupo_ident}!`,
                          dispatcher: (result) => {
                            if (result.isConfirmed) {
                              deleteAsigPorProfesor(original.id);
                            }
                          },
                        })
                      }
                      fontSize="small"
                    >
                      remove_circle
                    </Icon>
                  </Tooltip>
                </MDBox>
              </ComponentsPrivatization>
            </MDBox>
          );
        },
      },
    ],
    rows: asig_por_prof ?? [],
  };

  const IconComponent = ({ original }) => (
    <MDBox px={0.2}>
      <Tooltip
        placement="top"
        title="Ver vista previa de la boleta de notas del estudiante"
      >
        <Icon
          style={style_icon}
          onClick={() => handleClickShow(original)}
          fontSize="small"
        >
          picture_as_pdf
        </Icon>
      </Tooltip>
    </MDBox>
  );

  const tableEstudiante = {
    columns: [
      {
        Header: () => {
          return (
            <CheckBox
              id={"No."}
              checked={
                checkedAll && selectedItems.length === est_por_grupo.length
              }
              onChange={() => {
                setCheckedAll(!checkedAll);
              }}
            />
          );
        },
        accessor: "checkbox",
        width: "10%",
        Cell: ({ row }) => {
          const { original } = row;
          const { firmado } = original;
          return (
            <CheckBox
              disabled={firmado ? true : false}
              id={Number(row.id) + 1}
              checked={selectedItems.includes(row.original)}
              onChange={() => {
                const el = row.original;

                if (selectedItems.includes(el)) {
                  setSelectedItems((prevItems) =>
                    prevItems.filter((item) => item !== el)
                  );
                } else {
                  setSelectedItems((prevItems) => [...prevItems, el]);
                }
              }}
            />
          );
        },
      },
      { Header: "Matricula", accessor: "numero_de_control" },
      { Header: "Apellido Paterno", accessor: "apellido_paterno" },
      { Header: "Apellido Materno", accessor: "apellido_materno" },
      { Header: "Nombre", accessor: "nombre" },
      {
        Header: "Acciones",
        accessor: "actions",
        width: "1%",
        Cell: ({ row }) => {
          const { original } = row;
          const { firmado } = original;

          return (
            <MDBox display="flex">
              <ComponentsPrivatization
                permitted={[
                  FIRMANTE_BOLETA,
                  COORDINADOR_DOCENTE,
                  AUXILIAR_DOCENTE,
                ]}
              >
                {plantillaPDF &&
                  (cicloActivo === parseInt(ciclo_id) ? (
                    firmado && <IconComponent original={original} />
                  ) : (
                    <IconComponent original={original} />
                  ))}
              </ComponentsPrivatization>
              <ComponentsPrivatization permitted={[COORDINADOR_DOCENTE]}>
                {firmado && (
                  <MDBox px={0.2}>
                    <Tooltip
                      placement="top"
                      title="Eliminar la firma de la boleta"
                    >
                      <Icon
                        style={style_icon}
                        onClick={() =>
                          handleToast({
                            title: "¿Estás seguro?",
                            text: `¡Si eliminas la firma de la boleta esta acción no se podrá revertir!`,
                            dispatcher: (result) => {
                              if (result.isConfirmed) {
                                let boletaCorrespondiente = getBoleta.find(
                                  (elem) => elem.dist_est === original.id
                                );
                                deleteFirmaBoleta(boletaCorrespondiente.id);
                              }
                            },
                          })
                        }
                        fontSize="small"
                      >
                        delete
                      </Icon>
                    </Tooltip>
                  </MDBox>
                )}
              </ComponentsPrivatization>
              <ComponentsPrivatization
                permitted={[COORDINADOR_DOCENTE, AUXILIAR_DOCENTE]}
              >
                <MDBox display="flex">
                  <MDBox px={0.2}>
                    <Tooltip
                      placement="top"
                      title="Editar la asociación del estudiante al grupo (puede seleccionar otro estudiante a ese grupo pero no puede cambiar el grupo a ese estudiante)"
                    >
                      <Icon
                        style={style_icon}
                        onClick={() => handleClickEditEST(original)}
                        fontSize="small"
                      >
                        edit
                      </Icon>
                    </Tooltip>
                  </MDBox>
                  <MDBox px={0.2}>
                    <Tooltip
                      placement="top"
                      title="Eliminar la asociación del estudiante al grupo"
                    >
                      <Icon
                        style={style_icon}
                        onClick={() =>
                          handleToast({
                            title: "¿Estás seguro?",
                            text: `¡Si eliminas el estudiante: ${original.apellido_paterno} ${original.apellido_materno} ${original.nombre} del grupo: ${original.grupo}, la acción no se podrá revertir!`,
                            dispatcher: (result) => {
                              if (result.isConfirmed)
                                deleteEstPorGrupo(original.id);
                            },
                          })
                        }
                        fontSize="small"
                      >
                        remove_circle
                      </Icon>
                    </Tooltip>
                  </MDBox>
                </MDBox>
              </ComponentsPrivatization>
            </MDBox>
          );
        },
      },
    ],
    rows: est_por_grupo ?? [],
  };

  const handleClickEditEST = (el) => {
    navigate(
      `/ciclos/${cicle_name}/grupos/distribuciones/grupo/${el.dist_grupo_id}/ciclo_id/${ciclo_id}/estudiante/${el.id}`
    );
  };

  const handleClickEditProf = (el) => {
    navigate(
      `/ciclos/${cicle_name}/grupos/distribuciones/grupo/${el.dist_grupo_id}/asignatura/${el.id}`
    );
  };

  const handleClickShow = (original) => {
    const { firmado } = original;
    if (firmado) {
      let boletaCorrespondiente = getBoleta.find(
        (elem) => elem.dist_est === original.id
      );
      const canvas = document.getElementById(
        `qrCodeCertificado-${boletaCorrespondiente.id}`
      );
      if (canvas) {
        const dataURL = canvas.toDataURL();
        if (dataURL) {
          let obj = pdf(
            <BoletaFirmada
              boleta={original.notas}
              estudiante={original}
              qrImageSrc={dataURL}
              firma={boletaCorrespondiente}
              plantilla={plantillaPDF?.[0].imagenes}
            />
          ).toBlob();
          obj.then(function (blob) {
            let url = URL.createObjectURL(blob);
            window.open(url, "_blank");
          });
        }
      }
    } else {
      let obj = pdf(
        <BoletaSinFirmar
          boleta={original.notas}
          estudiante={original}
          plantilla={plantillaPDF?.[0].imagenes}
        />
      ).toBlob();
      obj.then(function (blob) {
        let url = URL.createObjectURL(blob);
        window.open(url, "_blank");
      });
    }
  };

  const descargarALLFirmadas = async () => {
    const zip = new JSZip();
    const promises = getBoleta.map(async (boletaFirmada) => {
      let estudianteCorrespondiente = est_por_grupo.find(
        (elem) => elem.id === boletaFirmada.dist_est
      );

      let canvas = document.getElementById(
        `qrCodeCertificado-${boletaFirmada.id}`
      );
      if (canvas) {
        const dataURL = canvas.toDataURL();
        if (dataURL) {
          let obj = await pdf(
            <BoletaFirmada
              boleta={estudianteCorrespondiente.notas}
              estudiante={estudianteCorrespondiente}
              qrImageSrc={dataURL}
              firma={boletaFirmada}
              plantilla={plantillaPDF?.[0].imagenes}
            />
          ).toBlob();
          const fileName = `${estudianteCorrespondiente.numero_de_control}, ${
            estudianteCorrespondiente?.ciclo
          }${ParidadPorSemestre(estudianteCorrespondiente.semestre)}.pdf`;
          zip.file(fileName, obj);
        }
      }
    });

    await Promise.all(promises);
    return zip;
  };

  const handleDescargar = async () => {
    setIsDownloading(true);
    let zip;
    if (cicloActivo === ciclo_id) {
      zip = await descargarALLFirmadas();
    } else {
      if (est_por_grupo.some((e) => e.firmado)) {
        zip = await descargarALLFirmadas();
      } else {
        zip = new JSZip();
        const promises = est_por_grupo.map(async (estudiante) => {
          let obj = await pdf(
            <BoletaSinFirmar
              boleta={estudiante.notas}
              estudiante={estudiante}
              plantilla={plantillaPDF?.[0].imagenes}
            />
          ).toBlob();
          const fileName = `${estudiante.numero_de_control}, ${
            estudiante?.ciclo
          }${ParidadPorSemestre(estudiante.semestre)}.pdf`;
          zip.file(fileName, obj);
        });

        await Promise.all(promises);
      }
    }
    if (zip) {
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(
        content,
        `${grupoById?.ciclo_interno.carrera.nombre}-${grupoById.grupo.identificador}.zip`
      );
    }
    setIsDownloading(false);
  };

  const ButtonAllComponent = () =>
    est_por_grupo && !isDownloading ? (
      <Tooltip
        placement="top"
        title="Descargar todas las boletas de los estudiantes asociados a este grupo"
      >
        <MDButton
          variant="gradient"
          color="dark"
          size="medium"
          onClick={() => handleDescargar()}
          circular
          iconOnly
        >
          <Icon>picture_as_pdf</Icon>
        </MDButton>
      </Tooltip>
    ) : (
      <CircularProgress size={"20px"} />
    );

  return (
    <MDBox pt={6} pb={3}>
      {open && (
        <ModalFirmar
          open={open}
          handleClose={handleClose}
          selected={selectedItems}
          curso={false}
        />
      )}
      {getBoleta?.map((boleta) => {
        return (
          <QRCodeCanvas
            id={`qrCodeCertificado-${boleta.id}`}
            key={boleta.id}
            style={{
              width: "3.6cm",
              height: "3.6cm",
              display: "none",
            }}
            value={generarURL(boleta.id, false)}
            bgColor={"#fff"}
            size={90}
            level={"H"}
            imageSettings={{ height: 3.6, width: 3.6 }}
          />
        );
      })}
      <Card>
        <MDBox m={2} p={3}>
          <MDBox display="flex" mb={1}>
            {grupoById && (
              <Grid container>
                <Grid container spacing={2} mb={1}>
                  <Grid item xs={12} sm={6} display={"flex"}>
                    <MDTypography variant="h5" fontWeight="bold">
                      Carrera:
                    </MDTypography>
                    <MDTypography variant="h5" fontWeight="regular" ml={1}>
                      {grupoById?.ciclo_interno.carrera.nombre}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6} display={"flex"}>
                    <MDTypography variant="h5" fontWeight="bold">
                      Plan de estudios:
                    </MDTypography>
                    <MDTypography variant="h5" fontWeight="regular" ml={1}>
                      {grupoById?.ciclo_interno.carrera.plan_de_estudio}
                    </MDTypography>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={10} mb={1} display={"flex"}>
                  <MDTypography variant="h5" fontWeight="bold">
                    {grupoById.ciclo_interno.carrera.duracion.identificador}:{" "}
                  </MDTypography>
                  <MDTypography variant="h5" fontWeight="regular" ml={1}>
                    {grupoById.ciclo_interno.numero_de_ciclo}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} lg={10} mb={1} display={"flex"}>
                  <MDTypography variant="h5" fontWeight="bold">
                    Grupo:
                  </MDTypography>
                  <MDTypography variant="h5" fontWeight="regular" ml={1}>
                    {grupoById.grupo.identificador}
                  </MDTypography>
                </Grid>
              </Grid>
            )}
            <Link to={`/ciclos/${cicle_name}/grupos`}>
              <MDButton variant="gradient" color="info" size="medium">
                Atras
              </MDButton>
            </Link>
          </MDBox>
          <Grid container display={"flex"} justifyContent="flex-start" mt={2}>
            <Grid item xs={12} lg={10} mb={1}>
              <MDTypography variant="h4" fontWeight="bold">
                Profesores con su asignatura asignados al grupo:
              </MDTypography>
            </Grid>
          </Grid>

          <MDBox mt={3} mb={1} ml={0.5}>
            <MDBox
              display="flex"
              justifyContent={"center"}
              alignItems={"center"}
            >
              {isLoadingAP ? (
                <CircularProgress
                  sx={{ marginBottom: "3rem" }}
                  color="inherit"
                />
              ) : (
                <DataTable
                  showTotalEntries={false}
                  entriesPerPage={false}
                  table={tableAsignaturaPorProfesor}
                />
              )}
            </MDBox>
          </MDBox>
          <Grid
            container
            display={"flex"}
            justifyContent="space-between"
            alignItems="center"
            mt={4}
          >
            <Grid item xs={12} lg={10} mb={1}>
              <MDTypography variant="h4" fontWeight="bold">
                Estudiantes asignados a este grupo:
              </MDTypography>
            </Grid>
            <Grid item display={"flex"} gap={"2rem"} alignItems={"center"}>
              {plantillaPDF &&
                (cicloActivo === parseInt(ciclo_id) ? (
                  est_por_grupo?.some((e) => e.firmado) && (
                    <ButtonAllComponent />
                  )
                ) : (
                  <ButtonAllComponent />
                ))}

              <ComponentsPrivatization permitted={FIRMANTE_BOLETA}>
                {cicloActivo === parseInt(ciclo_id) && (
                  <MDBox>
                    <MDButton
                      variant="gradient"
                      disabled={selectedItems.length === 0}
                      color="primary"
                      size="medium"
                      onClick={() =>
                        selectedItems.length === 0
                          ? enqueueSnackbar(
                              "Debe seleccionar en la tabla al menos un estudiante para poder realizar la firma.",
                              {
                                variant: "error",
                                autoHideDuration: 5000,
                              }
                            )
                          : handleOpen()
                      }
                    >
                      Firmar
                    </MDButton>
                  </MDBox>
                )}
              </ComponentsPrivatization>
            </Grid>
          </Grid>
          <MDBox mt={3} mb={1} ml={0.5}>
            <MDBox
              display="flex"
              justifyContent={"center"}
              alignItems={"center"}
            >
              {isLoadingAP ? (
                <CircularProgress
                  sx={{ marginBottom: "3rem" }}
                  color="inherit"
                />
              ) : (
                <DataTable
                  table={removeColumns(
                    tableEstudiante,
                    () =>
                      !JSON.parse(sessionStorage.getItem("user")).groups.some(
                        (role) => role.name === FIRMANTE_BOLETA
                      ),
                    ["checkbox"]
                  )}
                  showTotalEntries={false}
                  entriesPerPage={false}
                  isSorted={false}
                  indice={
                    !JSON.parse(sessionStorage.getItem("user")).groups.some(
                      (role) => role.name === FIRMANTE_BOLETA
                    )
                  }
                />
              )}
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </MDBox>
  );
};

export default GrupoShow;
