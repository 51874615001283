import MDBox from "components/MDBox";

import React, { useCallback, useEffect } from "react";
import { Autocomplete, Card, Icon } from "@mui/material";
import MDButton from "components/MDButton";
import { COORDINADOR_DOCENTE } from "roles";
import { useToggleDivisionMutation } from "services";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import {
  auth_state,
  calificaciones,
  setCarreraSelect,
  setDivision,
} from "slices";
import { useDispatch, useSelector } from "react-redux";
import { handleToast } from "../../../../../helpers/handleToast";
import ComponentsPrivatization from "config/component_privatization";
import { AUXILIAR_DOCENTE } from "roles";

const SelectCarrerasCalificaciones = ({ carreras, ind }) => {
  const { division, carreraSelect, ciclos } = useSelector(calificaciones);
  const dispatch = useDispatch();
  const { user_info: authUser } = useSelector(auth_state);
  const [toggleDivision, { isSuccess: isSuccessDivision }] =
    useToggleDivisionMutation();

  useEffect(() => {
    if (isSuccessDivision) {
      dispatch(
        setCarreraSelect({
          carrera: ciclos[ind].carreras.find(
            (carrera) => carrera.id === carreraSelect.id
          ),
          updateDivision: false,
        })
      );
    }
  }, [ciclos]);

  //Funcion encargada de cambiar la division(espacio de tiempo en el que se encuentran cursando las carreras ej, si estas en el 1er Semestre
  //las carreras estan cursando sus semestres impares) en la que se estan mostrando las calificaciones
  const handleDivision = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      const newValue = e.currentTarget.value; // Usa currentTarget para obtener el valor del botón
      if (division.division !== newValue) {
        dispatch(setDivision(carreraSelect.divisiones[newValue - 1]));
      }
    },
    [carreraSelect]
  );

  return (
    <Card style={{ marginBottom: "24px" }}>
      <MDBox display="flex" flexDirection="column" m={2}>
        <MDTypography variant="h4" fontWeight="bold">
          Mostrando las calificaciones asociadas a la carrera:
        </MDTypography>
        <MDBox mt={3} width={"70%"}>
          <Autocomplete
            disableClearable
            id="carrera"
            options={carreras ?? []}
            value={carreraSelect}
            onChange={(e, value) =>
              dispatch(
                setCarreraSelect({ carrera: value, updateDivision: true })
              )
            }
            getOptionLabel={(option) =>
              `${option.nombre} ${option.plan_de_estudio}`
            }
            isOptionEqualToValue={(option, value) => {
              return `${option.id}` === `${value.id}`;
            }}
            renderInput={(params) => (
              <MDInput
                id="input-carrera"
                name={"carrera-input"}
                label=""
                {...params}
              />
            )}
          />
        </MDBox>
        <ComponentsPrivatization
          permitted={[COORDINADOR_DOCENTE, AUXILIAR_DOCENTE]}
        >
          <MDBox display="flex" mr={2} mt={3}>
            {carreraSelect?.divisiones.map((div) => {
              return (
                <MDButton
                  value={div.division}
                  variant={
                    division.division_verbose === div.division_verbose
                      ? "outlined"
                      : "gradient"
                  }
                  color={
                    div.cerrada === true
                      ? "error"
                      : division.division_verbose === div.division_verbose
                        ? "success"
                        : "secondary"
                  }
                  size="medium"
                  onClick={handleDivision}
                  sx={{
                    marginRight: "10px",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                  }}
                  key={`button-${div.id}`}
                >
                  {div.division_verbose}
                  <ComponentsPrivatization permitted={[COORDINADOR_DOCENTE]}>
                    <MDBox
                      display="flex"
                      sx={{
                        marginLeft: "10px",
                        cursor: "alias",
                        border: 0,
                        borderRadius: "0.2rem",
                        backgroundColor: "lightsteelblue",
                      }}
                    >
                      <Icon
                        onClick={(e) => {
                          e.stopPropagation();
                          handleToast({
                            title: "¿Estás seguro?",
                            text: `¡La siguiente acción cambiará el estado del ${div.type} de ${
                              !div.cerrada ? "abierto" : "cerrado"
                            } a ${div.cerrada ? "abierto" : "cerrado"}.`,
                            icon: "question",
                            confirmButtonText: "Cambiar",
                            dispatcher: (result) => {
                              if (result.isConfirmed) {
                                toggleDivision(div.id)
                                  .unwrap()
                                  .then(() => {
                                    enqueueSnackbar(
                                      `Ha cambiado el estado de abierto con éxito del: ${div.division_verbose}`,
                                      {
                                        variant: "success",
                                        autoHideDuration: 3000,
                                      }
                                    );
                                  })
                                  .catch(() => {
                                    enqueueSnackbar(
                                      `Hubo algún problema al intentar cerrar el: ${div.division_verbose}`,
                                      {
                                        variant: "error",
                                        autoHideDuration: 3000,
                                      }
                                    );
                                  });
                              }
                            },
                          });
                        }}
                      >
                        edit
                      </Icon>
                    </MDBox>
                  </ComponentsPrivatization>
                </MDButton>
              );
            })}
          </MDBox>
        </ComponentsPrivatization>
      </MDBox>
    </Card>
  );
};

export default SelectCarrerasCalificaciones;
