/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================
 
 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)
 
 Coded by www.creative-tim.com
 
 =========================================================
 
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";

//React Hooks and Others
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { CircularProgress, Icon, Tooltip } from "@mui/material";
import PropTypes from "prop-types";

import ComponentsPrivatization from "config/component_privatization";
import { style_icon } from "helpers/global_vars";
import { AUXILIAR_DOCENTE, COORDINADOR_DOCENTE } from "roles";
import { useGetTemplatesQuery } from "services/escuela/modelos_pdf/usecases/getTemplates";

function DataTableModelosPdf({ tableHeader }) {
  const navigate = useNavigate();

  const { data: plantilla } = useGetTemplatesQuery({ filterParams: {} });

  const dataTable = {
    columns: [
      {
        Header: "Nombre de Plantilla",
        accessor: "nombre",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "Activa",
        accessor: "imagenes",
        Cell: ({ value }) => {
          return (
            <MDBox display="flex">
              <MDBox px={0.2}>
                <Icon
                  fontSize="small"
                  color={
                    value.find((e) => e.espacio === "header").imagen
                      ? "success"
                      : "error"
                  }
                >
                  lightbulb
                </Icon>
              </MDBox>
            </MDBox>
          );
        },
      },
      {
        Header: "Acciones",
        accessor: "actions",
        width: "1%",
        Cell: ({ row }) => {
          const { original } = row;

          return (
            <MDBox display="flex">
              <ComponentsPrivatization
                permitted={[AUXILIAR_DOCENTE, COORDINADOR_DOCENTE]}
              >
                <MDBox px={0.2}>
                  <Tooltip
                    placement="top"
                    title="Editar la información del estudiante"
                  >
                    <Icon
                      style={style_icon}
                      onClick={() => handleClickEdit(original)}
                      fontSize="small"
                    >
                      edit
                    </Icon>
                  </Tooltip>
                </MDBox>
              </ComponentsPrivatization>
            </MDBox>
          );
        },
      },
    ],

    rows: plantilla ?? [],
  };

  const handleClickEdit = ({ id, tipo }) => {
    navigate(`/configuración/modelos/editar/${id}/tipo/${tipo}`);
  };

  return (
    <MDBox pt={6} pb={3}>
      <Card>
        <MDBox
          display="flex"
          gap={2}
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent={"space-between"}
          alignItems={"center"}
          m={2}
          p={3}
        >
          <MDTypography variant="h5" fontWeight="medium">
            {tableHeader}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent={"center"} alignItems={"center"}>
          {plantilla ? (
            <DataTable table={dataTable} />
          ) : (
            <MDBox
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              justifyContent={"center"}
              alignItems={"center"}
              paddingY={4}
            >
              <CircularProgress size={"60px"} margin="auto" />
            </MDBox>
          )}
        </MDBox>
      </Card>
    </MDBox>
  );
}

DataTableModelosPdf.propTypes = {
  tableHeader: PropTypes.string.isRequired,
};

export default DataTableModelosPdf;
