/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// NewUser page components
import FormField from "components/FormField";
import { useGetExamenesQuery } from "../../../../../services";
import { Autocomplete, MenuItem } from "@mui/material";
import React from "react";
import { ESTADOS_ESTUDIANTES } from "../../../../../helpers/global_vars";

function ExamenInfo({ formData }) {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { nombre, descripcion, asistencia, anterior, estados, desfase } =
    formField;
  const {
    nombre: nombreV,
    estados: estadosV,
    descripcion: descripcionV,
    asistencia: asistenciaV,
    anterior: anteriorV,
    desfase: desfaseV,
  } = values;

  const {
    data: examenes,
    isError: isErrorG,
    isLoading: isLoadingG,
    error: errorG,
    isFetching,
  } = useGetExamenesQuery(
    {
      filter_params: {
        query: "{id,examen{nombre,url}}",
      },
      shape: {
        id: "id",
        url: "examen.url",
        nombre: "examen.nombre",
      },
    },
    {
      refetchOnReconnect: true,
    },
  );

  const handleChange = (event, values) => {
    setFieldValue(
      estados.name,
      values?.map((option) => option),
    );
  };

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={nombre.type}
              label={nombre.label}
              name={nombre.name}
              value={nombreV}
              placeholder={nombre.placeholder}
              error={errors.nombre && touched.nombre}
              success={nombreV?.length > 0 && !errors.nombre}
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item sm={4} xs={12}>
            <FormField
              type={asistencia.type}
              label={asistencia.label}
              name={asistencia.name}
              value={asistenciaV}
              placeholder={asistencia.placeholder}
              error={errors.asistencia && touched.asistencia}
              success={
                asistenciaV >= 0 &&
                asistenciaV <= 100 &&
                asistenciaV !== "" &&
                !errors.asistencia
              }
              onInput={(e) => {
                let value = ("" + e.target.value).replace(/[^(\d|\.)]/g, "");
                e.target.value = isNaN(Number(value)) ? 0 : Number(value);
              }}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormField
              select
              label={anterior.label}
              name={anterior.name}
              value={anteriorV === null ? "" : anteriorV}
              placeholder={anterior.placeholder}
              error={errors.anterior && touched.anterior}
              success={anteriorV?.length > 0 && !errors.anterior}
            >
              <MenuItem key="0" value={""}>
                No seleccionado
              </MenuItem>
              {examenes?.map((el) => (
                <MenuItem key={el.id} value={el.url}>
                  {el.nombre}
                </MenuItem>
              ))}
            </FormField>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormField
              select
              label={desfase.label}
              name={desfase.name}
              value={desfaseV}
              placeholder={desfase.placeholder}
              error={errors.desfase && touched.desfase}
              success={desfaseV?.length > 0 && !errors.desfase}
            >
              <MenuItem key="0" disabled value={""}>
                No seleccionado
              </MenuItem>
              <MenuItem key={"1"} value={0}>
                0
              </MenuItem>
              <MenuItem key={"2"} value={1}>
                1
              </MenuItem>
            </FormField>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={descripcion.type}
              label={descripcion.label}
              name={descripcion.name}
              value={descripcionV}
              placeholder={descripcion.placeholder}
              error={errors.descripcion && touched.descripcion}
              success={descripcionV?.length > 0 && !errors.descripcion}
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              multiple
              id="estados-estudiantes"
              options={ESTADOS_ESTUDIANTES}
              onChange={handleChange}
              value={estadosV}
              isOptionEqualToValue={(option, value) => {
                return `${option}` === `${value}`;
              }}
              renderInput={(params) => (
                <FormField
                  id="estados-estudiantes"
                  name={estados.name}
                  label={estados.label}
                  error={errors.estados && touched.estados}
                  success={estadosV?.length > 0 && !errors.estados}
                  {...params}
                />
              )}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for UserInfo
ExamenInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ExamenInfo;
