import { examenApi } from "../view/examen.service";
import _ from "lodash";

export const editExamen = examenApi.injectEndpoints({
  endpoints: (builder) => ({
    editExamen: builder.mutation({
      query: ({ idWrapper: id, ...rest }) => {
        return {
          url: `/v1/api/examenentidad/${id}/`,
          method: "PATCH",
          body: _.omit(rest, ["nombre", "descripcion", "idInternal"]),
        };
      },
      invalidatesTags: (result, error, { idWrapper: id }) =>
        result
          ? [
              { type: "Examenes", id },
              { type: "Examenes", id: "LIST" },
            ]
          : [{ type: "Examenes", id: "LIST" }],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        if (arg.nombre || arg.descripcion)
          dispatch(
            editExamen.endpoints.editExamenInternal.initiate({
              id: arg.idInternal,
              nombre: arg.nombre,
              descripcion: arg.descripcion,
            }),
          );
      },
    }),
    editExamenInternal: builder.mutation({
      query: ({ id, ...rest }) => {
        rest.nombre = rest.nombre?.trim();
        return {
          url: `/v1/api/examen/${id}/`,
          method: "PATCH",
          body: rest,
        };
      },
    }),
  }),
});

export const { useEditExamenMutation } = editExamen;
