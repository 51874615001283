import MDTypography from "../MDTypography";
import MDBox from "../MDBox";
import { Grid } from "@mui/material";
import _ from "lodash";

const LabelValueRow = ({ label, value }) => (
  <Grid item xs={12} sm={12} mb={1}>
    <MDBox display="flex" gap={1} alignItems="baseline">
      <MDTypography variant="h6" fontWeight="bold" color="dark">
        {label}:
      </MDTypography>
      <MDTypography variant="h6" fontWeight="regular" color="secondary">
        {value}
      </MDTypography>
    </MDBox>
  </Grid>
);

// Componente que agrupa los datos en bloques de 4 usando recursividad
const GroupedGrid = ({ data }) => {
  // Utilizamos _.chunk de lodash para dividir los datos en bloques de 4
  const chunkedData = _.chunk(data, 4);

  // Función recursiva para renderizar los bloques de 4 elementos
  const renderChunks = (chunks) => {
    // Caso base: si no quedan más bloques, retornamos null
    if (_.isEmpty(chunks)) {
      return null;
    }

    // Renderizamos el primer bloque (chunk) y llamamos recursivamente para el resto
    const [currentChunk, ...restChunks] = chunks;

    return (
      <>
        <Grid container item xs={12} sm={9} spacing={1}>
          {currentChunk.map(({ label, value }, idx) => (
            <Grid item xs={12} sm={8} key={idx}>
              <LabelValueRow label={label} value={value} />
            </Grid>
          ))}
        </Grid>
        {renderChunks(restChunks)} {/* Llamada recursiva */}
      </>
    );
  };

  return <Grid container>{renderChunks(chunkedData)}</Grid>;
};

export default GroupedGrid;
